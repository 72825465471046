import { applyMiddleware,createStore,compose } from 'redux'

import multi from 'redux-multi'
import thunk from 'redux-thunk'

import { Reducers } from './Reducers'

// ✅ Configuração correta do Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ✅ Criação da store com os middlewares
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(thunk, multi))
);

export default store;

//export default applyMiddleware(thunk,multi,promise)(createStore)(Reducers);
//export default applyMiddleware(thunk,multi)(createStore)(Reducers, /* preloadedState, */ window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()); 
