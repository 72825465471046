import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import * as config from '../../Config/environment.js';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {useNavigate,useParams } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Grid from '@mui/material/Grid';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { Slider } from "primereact/slider";
import { Galleria } from 'primereact/galleria';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';

import 'moment/locale/pt-br';
// import moment from "moment";
import moment from 'moment-business-days';

import {APIProvider, Map,Marker, InfoWindow} from '@vis.gl/react-google-maps';

import imagePlaceHolder from '../../Assets/Images/noimg.jpg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

import ExpireView from '../../Components/ExpireModal';
import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';
import RoomSelector from '../../Components/RoomSelector';
import MapViewModal from './MapviewModal';
import ImageGalleryZoomModal from './ImageGalleryZoomModal';
import ImageGalleryRoomModal from './ImageGalleryRoomModal';
import RoomAmenitiesModal from './RoomAmenitiesModal';
import PropertyPoiModal from './PropertyPoiModal';

// import Timer from '../../Components/Timer'; WEB WORKER FOR BACKGROUND TASK

// Load Componentes
import AlertView from '../../Components/AlertModal';

import {
	formatNumberCurrency,
    padronizarDecimal
} from '../../Components/Utils';


// export default function HomePage() {
const HotelDetails = (props) => {

const apiKey = 'AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs';
	
// Set var from props
const {
	setDialog,
	dialog,
	masterUser,
	currentAgency,
	searchType,
	searchParams,
	searchResultStatus,
	searchResultRowsNumber,
	searchResultToken,
	searchResultCurrentPage,
	searchResultLimit,
	searchLocation,
	searchResultObj,
    checkSearchResult,
    retrievePropertySearchResult,
    retrievePropertyPriceAndAvailability,
    searchCurrency,
	exchangeRates   
} = props;

const { id } = useParams();

const [propertyId, setPropertyId] = React.useState(null); 

const [loadingResults, setLoadingResults] = React.useState(false); 
const [loadingFiltering, setLoadingFiltering] = React.useState(false); 
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [pickingRoomLoading, setPickingRoomLoading] = React.useState({}); 
const [removingRoomLoading, setRemovingRoomLoading] = React.useState({}); 
const [hidingContent, setHidingContent] = React.useState(false); 

const [contentLoaded, setContentLoaded] = React.useState(false); 
const [hostingOption, setHostingOptions] = React.useState(false); 
const [currentStep, setCurrentStep] = React.useState(1); // 1 continent - 2 country - 3 city  - 4 options

const [contentLoading, setContentLoading] = React.useState(true); 
const [notFound, setNotFound] = React.useState(false); // Not found control

const [selectedItem, setSelectedItem] = React.useState(null); // Set selected item 

const [currentContinent, setCurrentContinent] = React.useState({}); // id and label
const [currentCountry, setCurrentCountry] = React.useState({}); // id and label
const [currentCity, setCurrentCity] = React.useState({}); // id and label

const [breadcrumbArray, setBreadcrumbArray] = React.useState([]); // id and label
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 
const [alertAction, setAlertAction] = React.useState(""); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartSearch"); 

const [currentPlaces, setCurrentPlaces] = React.useState([]); 		

const [hostsAndRoomsLabel, setHostsAndRoomsLabel] = React.useState(null); 
const [hostsLabel, setHostsLabel] = React.useState(null); 
const [roomsLabel, setRoomsLabel] = React.useState(null); 
const [dateRangeDays, setDateRangeDays] = React.useState(null); 

const [hostsAndRooms, setHostsAndRooms] = React.useState(null); 
const [rooms, setRooms] = React.useState([{ adults: 2, children: [] }]); // Default at least 1 adult
const [selectedRooms, setSelectedRooms] = React.useState([]); // Default at least 1 adult
const [totalAmount, setTotalAmount] = React.useState(0.00); // Default at least 1 adult
const [totalCollectedFeeAmount, setTotalCollectedFeeAmount] = React.useState(null); // Default at least 1 adult
const [roomsButtonDisabled, setRoomsButtonDisabled] = React.useState(false); // Default at least 1 adult

/* Temporary used to set selected room guests */
const [selectedGuests, setSelectedGuests] = React.useState({adults:1,children:[]});
const [selectedChildAges, setSelectedChildAges] = React.useState([]);
const [availableChildAges, setAvailableChildAges] = React.useState([]);

const [availableChildren, setAvailableChildren] = React.useState([]);
const [availableAdults, setAvailableAdults] = React.useState([]);
const [remainingAdults, setRemainingAdults] = React.useState(0);
const [remainingChildren, setRemainingChildren] = React.useState([]);

const [imageWithError, setImageWithError] = React.useState(0);


const toast = React.useRef(null);

/*console.log('[rooms]----------> '+JSON.stringify(rooms,null,2));

console.log('availableChildren ----------------> '+JSON.stringify(availableChildren,null,2));
console.log('availableAdults ----------------> '+JSON.stringify(availableAdults,null,2));

console.log('[remainingAdults]----------> '+JSON.stringify(remainingAdults,null,2));
console.log('[remainingChildren]----------> '+JSON.stringify(remainingChildren,null,2));

console.log('[selectedGuests]----------> '+JSON.stringify(selectedGuests,null,2));
console.log('[selectedChildAges]----------> '+JSON.stringify(selectedChildAges,null,2));*/



// Atualiza os valores de adultos e crianças quando `rooms` mudar
React.useEffect(() => {
    if (rooms && rooms.length > 0) {
        const allAdults = rooms.reduce((acc, room) => acc + room.adults, 0);
        const allChildren = rooms.flatMap(room => room.children ? room.children.map(child => child.age) : []);
       // const allChildren = rooms.flatMap(room => room.children?.map(child => child.age) || []);

        setAvailableAdults(allAdults);
        //setAvailableChildren([...new Set(allChildren)]); // Remove duplicatas
        setAvailableChildren([...allChildren])
        setRemainingAdults(allAdults);
        setRemainingChildren([...allChildren]); // Clona os valores iniciais

        
    
        // ✅ Mantém todas as instâncias sem remover duplicatas
        setAvailableChildren([...allChildren]);
    }
}, [rooms]); // Atualiza sempre que `rooms` mudar

React.useEffect(() => {
    if (selectedChildAges.length === 0) {
        setAvailableChildAges([...remainingChildren]); // Restaura todas as idades disponíveis
    }
}, [selectedChildAges, remainingChildren]);

const handleAddAdult = () => {
    setSelectedGuests(prev => {
        if (!canAddAdult()) return prev; // Impede adicionar adultos se a regra não permitir

        return { ...prev, adults: prev.adults + 1 };
    });
};

const handleRemoveAdult = () => {
    setSelectedGuests(prev => {
        if (prev.adults > 1) {
            return { ...prev, adults: prev.adults - 1 };
        }
        return prev;
    });
};

const handleAddChild = () => {
    setSelectedGuests(prev => {
        if (!canAddChild()) return prev; // Impede adicionar crianças se a regra não permitir

        return { ...prev, children: [...prev.children, null] }; // Placeholder até selecionar idade
    });
};

const handleRemoveChild = (childIndex) => {
    setSelectedChildAges(prev => {
        const newAges = [...prev];
        const removedAge = newAges.splice(childIndex, 1)[0]; // Remove a idade da criança removida

        if (removedAge !== undefined) {
            setAvailableChildAges(prevAges => [...prevAges, removedAge]); // Retorna a idade para o dropdown
        }

        return newAges;
    });

    // Atualiza o estado de crianças selecionadas
    setSelectedGuests(prev => {
        const newChildren = [...prev.children];
        newChildren.splice(childIndex, 1);
        return { ...prev, children: newChildren };
    });
};

const resetChildSelection = () => {
    setAvailableChildAges(prev => [...prev, ...selectedChildAges]); // Devolve as idades ao dropdown
    setSelectedChildAges([]); // Limpa as seleções de idades
    setSelectedGuests(prev => ({ ...prev, children: [] })); // Remove as crianças do estado selecionado
};

const handleChildAgeChange = (childIndex, value) => {
    setSelectedChildAges(prev => {
        const newAges = [...prev];
        const previousValue = newAges[childIndex];

        // Se já tinha uma idade selecionada, devolvemos ao availableChildAges
        if (previousValue !== undefined && previousValue !== value) {
            setAvailableChildAges(prevAges => [...prevAges, previousValue]);
        }

        // Atualiza a nova seleção e remove do availableChildAges
        newAges[childIndex] = value;
        setAvailableChildAges(prevAges => prevAges.filter(age => age !== value));

        return newAges;
    });
};


const confirmRoomSelection = (item, index) => {

    let searchRooms;

    if (rooms.length > 1) {
        
        // ✅ Verifica se todas as crianças possuem uma idade definida
        if (selectedChildAges.length !== selectedGuests.children.length) {
            setAlertTitle("Verifique as informações");
            setAlertBody("Todas as crianças devem ter uma idade selecionada antes de confirmar a escolha.");
            setAlert(true);
            return;
        }

        // ✅ Verifica se esta é a última seleção de quarto
        const totalRooms = rooms.length;
        const selectedRoomsCount = selectedRooms.length;
        
        if (selectedRoomsCount === totalRooms - 1) { // Último quarto a ser selecionado
            // ✅ Corrige a contagem de adultos restantes corretamente
            const adultsInUse = selectedRooms.reduce((acc, room) => acc + room.roomHostsArray?.adults, 0);
            const remainingAdultsFinal = (rooms.reduce((acc, room) => acc + room.adults, 0)) - adultsInUse;
        
            // ✅ Remove apenas as instâncias exatas das crianças selecionadas
            let tempRemainingChildren = [...remainingChildren];
            selectedGuests.children.forEach(childAge => {
                const index = tempRemainingChildren.indexOf(childAge);
                if (index !== -1) tempRemainingChildren.splice(index, 1);
            });
            const remainingChildrenFinal = tempRemainingChildren;

            // ✅ Bloqueia a seleção se ainda houver adultos ou crianças de fora no último quarto
            if (selectedGuests?.adults != remainingAdultsFinal || selectedGuests?.children.length != remainingChildrenFinal.length) {
                let missingMessage = (
                    <>
                        <p>Este é o último quarto, então é necessário selecionar os adultos e crianças restantes. Veja abaixo o que falta selecionar: <br /></p>
                        {remainingAdultsFinal > 0 && <div>{remainingAdultsFinal} adulto{remainingAdultsFinal > 1 ? 's' : ''}</div>}
                        {remainingChildrenFinal.length > 0 && <div>{remainingChildrenFinal.length} criança{remainingChildrenFinal.length > 1 ? 's' : ''}</div>}
                    </>
                );
        
                setAlertTitle("Verifique as informações");
                setAlertBody(missingMessage);
                setAlert(true);
                return;
            }
        }

        // Retrieve Room price and availbility
        searchRooms = [{ adults: selectedGuests?.adults, children: selectedChildAges }];

    } else {

       let childAges = rooms[0].children.length > 0 ? rooms[0].children.map(child => child.age) : [];
       searchRooms =  [{ adults: rooms[0]?.adults, children: childAges }];
       
    }
  
    let objKey = item.room['room-id'] + '-' + index;
    
    // Atualiza apenas o botão específico para loading
    setPickingRoomLoading(prevState => ({
        ...prevState,  
        [objKey]: true 
    }));

	// Prepare props and data
	var props = {
        setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,

        setAlertExpire:setAlertExpire,
		setAlertTitleExpire:setAlertTitleExpire,
		setAlertBodyExpire:setAlertBodyExpire,
		setAlertActionExpire:setAlertActionExpire,

		setPickingRoomLoading:setPickingRoomLoading,
        pickRoom:pickRoom
	}    
	let data = {
		searchResultToken:searchResultToken,
        propertyId:propertyId,
        searchRooms:searchRooms,
        roomId:item.room['room-id'],
        ratePlanCode:item['rate-plan-code'],
        item:item,
        index:index,
        objKey:objKey,
        filters:searchFilters
	}		
    // pickRoom will be called inside retrievePropertyPriceAndAvailability
    retrievePropertyPriceAndAvailability(data,props);

    // ✅ Chama `pickRoom` passando os valores corretos
    //pickRoom(item, index);

};

/* Activate Success Toast  */
const showSuccess = (title="",message="") => {
    toast.current.show({severity:'success', summary: title, detail:message, life: 6000});
}

const [dateRange, setDateRange] = React.useState([]); 
const [formattedValue, setFormattedValue] = React.useState('');

const prevDateRangeRef = React.useRef(null); // to save precvious dateRange before change

// Função para manipular a mudança da data
const handleDateChange = (e) => {
    // Apenas salva o valor anterior se for um intervalo completo (duas datas válidas)
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      prevDateRangeRef.current = [...dateRange]; // Faz uma cópia do array
    }
  
    setDateRange(e.value);
  };
  
  // Função para reverter a mudança da data
  const revertToPreviousDate = () => {
    if (prevDateRangeRef.current !== null) {
      setDateRange(prevDateRangeRef.current);
    }
};

const [filterByCity, setFilterByCity] = React.useState(null); 
const [filterByCountry, setFilterByCountry] = React.useState(null); 

const [showCancelButton, setShowCancelButton] = React.useState(true); 

const calendarRef = React.useRef(null);
const overlayPanelRef = React.useRef(null);

const [counter, setCounter] = React.useState(1200);
const [counterInitial, setCounterInitial] = React.useState(1200);

// const [counter, setCounter] = React.useState(20); // for test. Force popup. 
const [counterLabel, setCounterLabel] = React.useState("00:20:00");
const [counterValue, setCounterValue] = React.useState(100);

const [locationImage, setLocationImage] = React.useState(null);
const [addressLocationText, setAddressLocationText] = React.useState(null);
const [resultCard, setResultCard] = React.useState({});
const [resultsRooms, setResultsRooms] = React.useState([]);
const [resultsPois, setResultsPois] = React.useState([]);

const [imageLoading, setImageLoading] = React.useState(true);    

const [descriptionDetail, setDescriptionDetail] = React.useState(false);

const [images, setImages] = React.useState(null);
const [activeIndex, setActiveIndex] = React.useState(0);    

const [roomImages, setRoomImages] = React.useState(null);
const [roomInfo, setRoomInfo] = React.useState(null);
const [roomData, setRoomData] = React.useState(null);

const galleria = React.useRef(null);

const [center, setCenter] = React.useState(null);
const [markerCenter, setMarkerCenter] = React.useState(null);
const [selectedPoi, setSelectedPoi] = React.useState(null);
const [initialZoom, setInitialZoom] = React.useState(null);
const [zoom, setZoom] = React.useState(initialZoom || 11);

const [searchFilters, setSearchFilters] = React.useState(null); // Force search filter to refresh current search


const [activeTabIndex, setActiveTabIndex] = React.useState(0); // control Tab index


// console.log('[searchFilters]----------------------> '+JSON.stringify(searchFilters,null,2));
//console.log('[resultCard]----------> '+JSON.stringify(resultCard,null,2)); 
console.log('[resultsRooms]----------> '+JSON.stringify(resultsRooms,null,2)); 

/* Get out and get in to screen control.  */
const [lastHiddenTime, setLastHiddenTime] = React.useState(null);
const timeoutRef = React.useRef(null);
const timeoutMinutes = config.BUSINESS_RULES.timeoutMinutes; // Tempo em minutos

// Configurar feriados nacionais e municipais do Rio de Janeiro
moment.updateLocale('pt-br', {
    holidays: config.BUSINESS_RULES.holidays,
    holidayFormat: 'YYYY-MM-DD'
});

// Cards panel handlers and control
const [visiblePanel, setVisiblePanel] = React.useState(null);

const handleSelect = (id) => {
  setVisiblePanel(id);
};

const handleClose = () => {

  resetChildSelection();

  setSelectedGuests({adults:1,children:[]});
  setVisiblePanel(null);

};

/**
 * Handles the selection of a room.
 *
 * @param {Object} item - The room item to be selected.
 * @param {number} index - The index of the room item.
 * @returns {void}
 */

const pickRoom = (item, index) => {
    
    let objKey = item.room['room-id'] + '-' + index;
    let randPart = Math.floor(Math.random() * 1000);

    // Atualiza apenas o botão específico para loading
    setPickingRoomLoading(prevState => ({
        ...prevState,  
        [objKey]: true 
    }));

    let roomHostsLabel, roomHostsArray;

    if (rooms.length === 1) {
        roomHostsLabel = extractAllHosts(rooms, 'label');
        roomHostsArray = extractAllHosts(rooms, 'array');
    } else {

        let newGuestsArray = {...selectedGuests};
        newGuestsArray.children = [...selectedChildAges];

        roomHostsLabel = extractSelectedHosts(selectedGuests, 'label');
        roomHostsArray = extractSelectedHosts(newGuestsArray, 'array');

        // const childrenToRemove = [...selectedGuests.children];
        const childrenToRemove = [...selectedChildAges];

        // Atualiza `remainingAdults`
        setRemainingAdults(prev => prev - selectedGuests.adults);

        // ✅ Atualiza `remainingChildren` corretamente, garantindo que as idades sejam removidas
        /*setRemainingChildren(prev => {
            const updatedChildren = prev.filter(age => !childrenToRemove.includes(age));
            console.log("🔄 Atualizando remainingChildren:", updatedChildren);
            return updatedChildren;
        });*/
        setRemainingChildren(prev => {
            let updatedChildren = [...prev];
        
            selectedChildAges.forEach(childAge => {
                const index = updatedChildren.indexOf(childAge);
                if (index !== -1) updatedChildren.splice(index, 1); // ✅ Remove apenas UMA instância específica
            });
        
            console.log("🔄 Atualizando remainingChildren após adicionar quarto:", updatedChildren);
            return updatedChildren;
        });        

        setTimeout(() => {
            handleClose(item.room['room-id'] + '-' + index);
        }, 1000);
    }

    // Adiciona o quarto ao array de quartos selecionados
    setSelectedRooms(prevState => [
        ...prevState, 
        { 
            ...item, 
            uniqueId: objKey + '-' + randPart, 
            roomHostsLabel: roomHostsLabel, 
            roomHostsArray: roomHostsArray  
        }
    ]);

    setTimeout(() => {

        // ✅ Reseta `selectedGuests` e `selectedChildAges` APÓS todas as atualizações
        setSelectedGuests({ adults: 2, children: [] });
        setSelectedChildAges([]); // Limpa as idades selecionadas para evitar problemas na próxima seleção

        // Remove o estado de loading apenas para esse botão
        setPickingRoomLoading(prevState => ({
            ...prevState,
            [objKey]: false 
        }));

        handleClickScroll('navigationSection');

        
        setTimeout(() => {

            showSuccess("Quarto selecionado com sucesso!","Confira o total no Resumo das seleções. Para trocar, exclua o quarto e escolha outro abaixo.");

            /*setAlertTitle("Ótimo! Quarto selecionado com sucesso!");
            setAlertBody("Você selecionou o quarto. Confira o total à direita. Para trocar, exclua o quarto na direita e escolha outro abaixo.");
            setAlert(true);*/
        }, 1000);
        
    }, 700);
};


/**
 * Removes a room with the specified unique ID.
 *
 * This function updates the loading state for the specific room button,
 * removes the room from the selected rooms list, and displays a success alert.
 *
 * @param {string} uniqueId - The unique identifier of the room to be removed.
 */
const removeRoom = (uniqueId) => {

    // Atualiza apenas o botão específico para loading
    setRemovingRoomLoading(prevState => ({
        ...prevState,  
        [uniqueId]: true 
    }));

    setTimeout(() => {
        // Remove o estado de loading apenas para esse botão
        setRemovingRoomLoading(prevState => ({
            ...prevState,
            [uniqueId]: false 
        }));

        setSelectedRooms(prevState => {

            const removedRoom = prevState.find(room => room.uniqueId === uniqueId);
            if (!removedRoom) {
                console.warn("Nenhum quarto encontrado para remoção!");
                return prevState;
            }

            const newSelectedRooms = prevState.filter(room => room.uniqueId !== uniqueId);

            // Se o array não mudou, não atualiza o estado
            if (newSelectedRooms.length === prevState.length) {
                console.warn("Nenhuma mudança detectada em selectedRooms!");
                return prevState;
            }

            // ✅ Restaurando adultos corretamente
           /* setRemainingAdults(prev => {
                const restoredAdults = removedRoom?.roomHostsArray?.adults || 0;
                return Math.min(prev + restoredAdults, rooms.reduce((acc, room) => acc + room.adults, 0));
            });*/

            /*setRemainingAdults(prev => {
                const restoredAdults = removedRoom.roomHostsArray?.adults || 0;
            
                // ✅ Garante que `prev` nunca será `null` ou `undefined`
                const currentRemainingAdults = prev !== null && prev !== undefined ? prev : 0;
            
                // ✅ Obtém o número total de adultos originalmente disponíveis
                const totalAvailableAdults = rooms.reduce((acc, room) => acc + (room.adults || 0), 0);
            
                // ✅ Calcula o número de adultos já em uso
                const adultsInUse = totalAvailableAdults - currentRemainingAdults;
            
                // ✅ Calcula o novo número de adultos, sem ultrapassar o valor correto
                const newRemainingAdults = Math.max(totalAvailableAdults - (adultsInUse - restoredAdults), 0);
            
                console.log(`🔄 Restaurando remainingAdults: Total = ${totalAvailableAdults}, Em uso = ${adultsInUse}, Restaurando = ${restoredAdults}, Novo = ${newRemainingAdults}`);
                return newRemainingAdults;
            });*/

            setRemainingAdults(prev => {
                const restoredAdults = removedRoom.roomHostsArray?.adults || 0;
            
                // ✅ Calcula o total de adultos originalmente disponíveis
                const totalAvailableAdults = rooms.reduce((acc, room) => acc + (room.adults || 0), 0);
            
                // ✅ Calcula o total de adultos em uso nos quartos restantes
                const adultsInUse = selectedRooms
                    .filter(room => room.uniqueId !== removedRoom.uniqueId) // Ignora o quarto removido
                    .reduce((acc, room) => acc + (room.roomHostsArray?.adults || 0), 0);
            
                // ✅ Calcula os adultos restantes, sem ultrapassar o total inicial
                const newRemainingAdults = totalAvailableAdults - adultsInUse;
            
                console.log(`🔄 Restaurando remainingAdults: ${newRemainingAdults} (restaurado ${restoredAdults} adultos do quarto removido)`);
                return newRemainingAdults;
            });            


            // ✅ Restaurando corretamente as crianças
            /*setRemainingChildren(prev => {
                const restoredChildren = Array.isArray(removedRoom?.roomHostsArray?.children) 
                    ? removedRoom.roomHostsArray.children 
                    : [];

                // Filtra valores `null` e remove duplicatas
                const updatedChildren = [...prev, ...restoredChildren]
                    .filter(age => age !== null && age !== undefined)
                    .filter((age, index, self) => self.indexOf(age) === index);

                console.log("🔄 Restaurando remainingChildren:", updatedChildren);
                return updatedChildren;
            });*/

            // ✅ Restaurando corretamente as crianças (incluindo duplicatas)
           /* setRemainingChildren(prev => {
                const restoredChildren = Array.isArray(removedRoom?.roomHostsArray?.children) 
                    ? removedRoom.roomHostsArray.children 
                    : [];

                // Agora adicionamos **todas** as idades de volta, sem remover duplicatas
                const updatedChildren = [...prev, ...restoredChildren]
                    .filter(age => age !== null && age !== undefined);

                console.log("🔄 Restaurando remainingChildren:", updatedChildren);
                return updatedChildren;
            });*/            
            
            setRemainingChildren(prev => {
                const restoredChildren = Array.isArray(removedRoom?.roomHostsArray?.children) 
                    ? removedRoom.roomHostsArray.children 
                    : [];

                let updatedChildren = [...prev];

                restoredChildren.forEach(childAge => {
                    // ✅ Insere a idade de volta apenas se a contagem original permitir
                    const countInOriginal = availableChildren.filter(age => age === childAge).length;
                    const countInUpdated = updatedChildren.filter(age => age === childAge).length;

                    if (countInUpdated < countInOriginal) {
                        updatedChildren.push(childAge);
                    }
                });

                console.log("🔄 Restaurando remainingChildren após remover quarto:", updatedChildren);
                return updatedChildren;
            });

            return newSelectedRooms;
        });



        setTimeout(() => {

            showSuccess("Quarto removido com sucesso!","Você removeu o quarto selecionado. Selecione outro quarto abaixo.");

           /* setAlertTitle("Ótimo! Quarto removido com sucesso!");
            setAlertBody("Você removeu o quarto selecionado. Selecione outro quarto abaixo.");
            setAlert(true);	*/

        }, 300);
            
    }, 700);
};

const geocodeAddress = async (address) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();
    if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
    } else {
        throw new Error('Geocoding API error: ' + data.status);
    }
};	

const getUsedAdults = () => {
    return selectedRooms.reduce((total, room) => {
        const adultsInRoom = room.roomHostsArray?.find(host => host.includes("adulto"));
        if (!adultsInRoom) return total;
        
        // Extrai o número de adultos do texto "2 adultos", "1 adulto", etc.
        const adultsCount = parseInt(adultsInRoom.match(/\d+/)?.[0] || "0", 10);
        return total + adultsCount;
    }, 0);
};

const getAvailableAges = (childIndex) => {
    // ✅ Pega as idades selecionadas até o momento
    const selectedAges = selectedChildAges.slice(0, childIndex);

    // ✅ Copia as idades disponíveis e remove apenas UMA instância das já escolhidas
    let remainingAges = [...remainingChildren];

    selectedAges.forEach(age => {
        const index = remainingAges.indexOf(age);
        if (index !== -1) remainingAges.splice(index, 1);
    });

    return remainingAges;
};

const canAddAdult = () => {
    return selectedGuests.adults < getMaxAdultsForCurrentRoom();
};

const canAddChild = () => {
    return selectedGuests.children.length < remainingChildren.length;
};

const getMaxAdultsForCurrentRoom = () => {
    const totalRooms = rooms.length;
    const totalSelectedRooms = selectedRooms.length;

    // ✅ Se todos os quartos já foram selecionados, podemos usar todos os adultos restantes
    if (totalSelectedRooms === totalRooms - 1) {
        return remainingAdults;
    }

    // ✅ Caso contrário, reservamos um adulto para cada quarto restante
    return remainingAdults - (totalRooms - totalSelectedRooms - 1);
};

const sortRooms = (rooms) => {

    const roomGroups = {};

    // 1. Agrupar quartos pelo nome exato
    rooms.forEach(room => {
      const roomName = room.room.title; // Usa o nome exato
  
      if (!roomGroups[roomName]) {
        roomGroups[roomName] = [];
      }
      roomGroups[roomName].push(room);
    });
  
    // 2. Ordenar os quartos dentro de cada grupo do menor para o maior valor
    Object.values(roomGroups).forEach(group => {
      group.sort((a, b) => parseFloat(a.totalWithMarkup) - parseFloat(b.totalWithMarkup));
    });
  
    // 3. Criar um array de grupos ordenados pelo menor preço dentro do grupo
    const sortedGroups = Object.values(roomGroups).sort((a, b) => {
      const minPriceA = a[0].totalWithMarkup;
      const minPriceB = b[0].totalWithMarkup;
      
      return minPriceA - minPriceB;
    });
  
    // 4. Desagrupar mantendo a ordem dos grupos
    const sortedRooms = sortedGroups.flat();
  
    return sortedRooms;    

}


// Exchange rate USD
const [usdExchangeRate, setUsdExchangeRate] = React.useState(null);
const [exchangeRateInitials, setExchangeRateInitial] = React.useState("USD");

const [filterAmenitiesValues, setFilterAmenitiesValues] = React.useState([]);
const [showAllAmenities, setShowAllAmenities] = React.useState(false);
const onFilterAmenitiesChange = (e) => {
	let _amenities = [...filterAmenitiesValues];

	if (e.checked)
		_amenities.push(e.value);
	else
	_amenities.splice(_amenities.indexOf(e.value), 1);

	setFilterAmenitiesValues(_amenities);
}

const [filterFoodValues, setFilterFoodValues] = React.useState([]);
const [showAllFood, setShowAllFood] = React.useState(false);
const onFilterFoodChange = (e) => {
	let _food = [...filterFoodValues];

	if (e.checked)
		_food.push(e.value);
	else
	_food.splice(_food.indexOf(e.value), 1);

	setFilterFoodValues(_food);
}

const [filterPOIValues, setFilterPOIValues] = React.useState([]);
const [showAllPOI, setShowAllPOI] = React.useState(false);
const onFilterPOIChange = (e) => {
	let _poi = [...filterPOIValues];

	if (e.checked)
		_poi.push(e.value);
	else
	_poi.splice(_poi.indexOf(e.value), 1);

	setFilterPOIValues(_poi);
}

const secondsToHmsOriginal = (d) => {
   
	d = Number(d);
	var h = Math.floor(d / 3600);
	var m = Math.floor(d % 3600 / 60);
	var s = Math.floor(d % 3600 % 60);
 
	var hDisplay = h > 0 ? h + (h == 1) : "00";
	var mDisplay = m > 0 ? m + (m == 1) : "00";
	var sDisplay = s > 0 ? s + (s == 1) : "00";
 
	let hour = ""+hDisplay+"";
	let minutes = ""+mDisplay+"";
	let seconds = ""+sDisplay+"";
 
	// return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
	return minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
 }

const secondsToHms = (d) => {
   
   d = Number(d);
   var h = Math.floor(d / 3600);
   var m = Math.floor(d % 3600 / 60);
   var s = Math.floor(d % 3600 % 60);

   var hDisplay = h > 0 ? h + (h == 1) : "00";
   var mDisplay = m > 0 ? m + (m == 1) : "00";
   var sDisplay = s > 0 ? s + (s == 1) : "00";

   let hour = ""+hDisplay+"";
   let minutes = ""+mDisplay+"";
   let seconds = ""+sDisplay+"";

   // return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
   return minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
}

const handleRoomSelection = (value) => {
	setHostsAndRooms(value);
	overlayPanelRef.current.hide();
};

const handleClearSelection = () => {
	setRooms([{ adults: 2, children: [] }]);
	// setHostsAndRooms(null);	
	overlayPanelRef.current.hide();
};

// Check if everything is ok with seleciton
const handleCheckSelectedOptions = (rooms) => {

	// Check if any children dont have defined age
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Ops! Defina a idade da(s) criança(s)");
				setAlertBody("É necessário que você defina as idades das crianças que foram adicionadas. Abra o seletor de quartos e informe a idade das crianças.");
				setAlert(true);	
								
				handleUpdateInputValue(rooms); // update current selection
				return false;
			}
		}
	}



}

const handleUpdateInputValue = (rooms) => {
	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];
	if (adultCount > 0) parts.push(`${adultCount} ${adultLabel}`);
	if (childCount > 0) parts.push(`${childCount} ${childLabel}`);
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) parts.push(`${rooms.length} ${roomLabel}`);

	setHostsAndRooms(parts.join(', '));	
};

const handleUpdateInputValueLabel = (rooms) => {

	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];

    const hostsArray = [];
    const roomsArray = [];

	if (adultCount > 0) {parts.push(`${adultCount} ${adultLabel}`);hostsArray.push(`${adultCount} ${adultLabel}`)};
	if (childCount > 0) {parts.push(`${childCount} ${childLabel}`);hostsArray.push(`${childCount} ${childLabel}`)};
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) {parts.push(`${rooms.length} ${roomLabel}`);roomsArray.push(`${rooms.length} ${roomLabel}`)};

	setHostsAndRoomsLabel(parts.join(', '));	

    setHostsLabel(hostsArray.join(', '))
    setRoomsLabel(roomsArray);

};

const extractAllHosts = (rooms, type) => { 
    const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
    const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

    // Corrigindo a extração das crianças
    const childrenArray = rooms.flatMap(room => room.children);

    const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
    const childLabel = childCount > 1 ? 'crianças' : 'criança';

    const parts = [];
    const hostsArray = {}; // Corrigindo para um objeto

    if (adultCount > 0) {
        parts.push(`${adultCount} ${adultLabel}`);
        hostsArray.adults = adultCount; // Usando objeto corretamente
    }
    if (childCount > 0) {
        parts.push(`${childCount} ${childLabel}`);
        hostsArray.children = childrenArray; // Agora armazenará corretamente todas as crianças
    }

    return type === 'label' ? parts.join(', ') : hostsArray;	
};

const extractSelectedHosts = (selectedGuests, type) => {
    const adultCount = selectedGuests.adults;
    const childCount = selectedGuests.children.length;

    const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
    const childLabel = childCount > 1 ? 'crianças' : 'criança';

    const parts = [];
    const hostsArray = {};
    const roomsArray = [];    

    if (adultCount > 0) {parts.push(`${adultCount} ${adultLabel}`);hostsArray['adults'] = adultCount;}
    if (childCount > 0) {parts.push(`${childCount} ${childLabel}`);hostsArray['children'] = selectedGuests.children;}

    return type === 'label' ? parts.join(', ') : hostsArray;
};

const handleClickScroll = (target,displacement=70) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop - displacement;
		element.scrollIntoView({ top: elementPosition,behavior: 'smooth' });
	}
	};  

React.useEffect(() => {
	if (!hostsAndRooms) {
		setHostsAndRooms(null);
	}	
}, [hostsAndRooms]);


React.useEffect(() => {
	handleUpdateInputValue(rooms);
}, [rooms]);

React.useEffect(() => {

    if (resultCard.id) {

        // Set location
        // let addressText = `${currentContinent} `;
        let addressText = `${resultCard.latitude},${resultCard.longitude}`;
        setAddressLocationText(addressText);

        let locationImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${addressText}&zoom=11&size=416x142&maptype=roadmap&key=AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs`;
        setLocationImage(locationImageUrl);  

        geocodeAddress(addressText)
        .then(coords => {
            setCenter(coords);
            setMarkerCenter(coords);
        })
        .catch(error => {
            console.error('Error fetching geocode:', error);
        });   
                

    }


}, [resultCard]);

React.useEffect(() => {
	if (dateRange && dateRange[0] && dateRange[1]) {
		const diffTime = Math.abs(dateRange[1] - dateRange[0]);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const formattedRange = formatDateRange(dateRange);
		let nightLabel = diffDays == 1 ? "noite" : "noites";
		setFormattedValue(`${formattedRange} (${diffDays} ${nightLabel})`);
	} else {
		setFormattedValue('');
	}
}, [dateRange]);

React.useEffect(() => {


    // Disable rooms buttons if rooms uantity is filled
    if (selectedRooms.length === rooms.length) {
        setRoomsButtonDisabled(true);
    } else {
        setRoomsButtonDisabled(false);
    }

    // Se selectedRooms estiver vazio, resetamos o total
    if (selectedRooms.length === 0) {
        setTotalAmount(0);
        return;
    }

    // Calcula a soma total antes de atualizar o estado
    const total = selectedRooms.reduce((sum, item) => sum + item.updatedPriceWithMarkup, 0);

    // Atualiza o estado apenas uma vez
    setTotalAmount(total);

    // Atualiza Collcted Fees Amount
    const totalFees = selectedRooms.reduce((sum, item) => sum + item?.localCollectedFee?.total, 0);
    console.log('totalFees-------------------> '+JSON.stringify(totalFees,null,2));
    setTotalCollectedFeeAmount(totalFees == 0 ? null : totalFees);
        

}, [selectedRooms]); // Executa sempre que selectedRooms mudar


const formatDateRange = (range) => {
	if (range && range[0] && range[1]) {
		const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
		return `${range[0].toLocaleDateString('pt-BR', options)} - ${range[1].toLocaleDateString('pt-BR', options)}`;
	}
	return '';
};
  
const Countdown = (props) => {
	const { startTime } = props;
	const [counter, setCounter] = React.useState(startTime);
	const [counterInitial] = React.useState(60);
	const [counterLabel, setCounterLabel] = React.useState("00:60");
	const [counterValue, setCounterValue] = React.useState(100);
  
	React.useEffect(() => {
	  if (counter > 0) {
		const timer = setTimeout(() => {
		  setCounter((prevCounter) => {
			const newCounter = prevCounter - 1;
			setCounterLabel(secondsToHms(newCounter));
			setCounterValue((100 * newCounter) / counterInitial);  
			return newCounter;
		  });
		}, 1000);
  
		return () => clearTimeout(timer);
	  }
	}, [counter]);
  
	return (
	  <>
		{counter > 0 ? (
		  <>Aguarde até {counterLabel} segundos...</>
		) : (
		  <>Redirecionando para resultado...</>
		)}
	  </>
	);
  };   

const customFooter = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"left" }}>
		 <button type="button" onClick={() => calendarRef.current.hide()} className="p-button p-component p-button-text p-button-plain" style={{ float: 'right' }}>
                    Fechar
        </button>
		</div>

		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

const customFooterInline = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});


// set Use of history for PUSH 
var navigate = useNavigate();

// Reload Controls, breadcrumb and map elements
const loadInterfaceElements = (searchFiltersArray=null) => {

   /*let coordinates = {lat:52.35879898,lng:4.893459797};
   setCenter(coordinates);
   setMarkerCenter(coordinates);  */ 

  // console.log('[searchFilters]----------[ INTERFACE ]----------> '+JSON.stringify(searchFiltersArray,null,2));

      // Set date range and rooms
      setDateRange(searchFiltersArray ? searchFiltersArray.dateRange : searchParams.dateRange);
      setDateRangeDays(searchFiltersArray ? searchFiltersArray.dateRangeDays : searchParams.dateRangeDays);
      setRooms(searchFiltersArray ? searchFiltersArray.rooms : searchParams.rooms);	

      // Set Rooms label for list
      handleUpdateInputValueLabel(searchParams.rooms);	

      // Reset selectedRooms
      setSelectedRooms([]);

}


// Set filters and execute search
const updateRetrieveProperty = () => {

    //setLoadingResults(true);handleTimeout()

    // HIDE current content
   // setHidingContent(true)

    // Set loading content
    setContentLoading(true);

    // Set current filters

    const diffTime = Math.abs(dateRange[1] - dateRange[0]);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // set number os days
    let dateRangeDaysValue = diffDays;

    let currentSearchFilters = {
        dateRange:dateRange,
        dateRangeDays:dateRangeDaysValue,
        rooms:rooms
    }
    setSearchFilters(currentSearchFilters);
   
   executeRetrieveProperty(id,currentSearchFilters);

}

const executeRetrieveProperty = (propertyId=id,filters=searchFilters) => {

	// Prepare props and data
	var props = {
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
        setAlertAction:setAlertAction,
		setAlertExpire:setAlertExpire,
		setAlertTitleExpire:setAlertTitleExpire,
		setAlertBodyExpire:setAlertBodyExpire,
		setAlertActionExpire:setAlertActionExpire,		
		setContentLoading:setContentLoading,
		setLoadingResults:setLoadingFiltering,
		setResultCard:setResultCard,
		setResultsRooms:setResultsRooms,
		setResultsPois:setResultsPois,
        setImages:setImages,
        navigate:navigate,
        loadInterfaceElements:loadInterfaceElements,
        setSearchFilters:setSearchFilters,
        revertToPreviousDate:revertToPreviousDate
	}
	var data = {
		searchResultToken:searchResultToken,
        propertyId:propertyId,
        filters:filters
	}		

	// if searchType is location
	retrievePropertySearchResult(data,props);

}
	
// Runs when opens component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  
  // set scroll to top
  window.scrollTo(0, 1);

  // Set property Id
  setPropertyId(id);
    
  // Retrieve current Property
  executeRetrieveProperty(id);

// Set USD exchange Rate and currency initials
if (searchParams?.country?.code == "BR") {
    const result = exchangeRates.find(item => item.targetCurrencyInitials === "USD");
    const exchangeRateValue = result.value;
    setUsdExchangeRate(exchangeRateValue); 
    setExchangeRateInitial("BRL"); 
}  

  // Load Interface elements
  loadInterfaceElements(); 
  
    
  // Runs when UNMOUNT component
  return () => {

	setContentLoading(true); 
	setContentLoaded(false); 
	setHostingOptions(false); 
	setCurrentStep(1); 

	setCurrentContinent({}); 
	setCurrentCountry({}); 
	setCurrentCity({}); 
	setBreadcrumbArray([]); 

	setCurrentPlaces([]); 		
	setHostsAndRooms(null); 
	setRooms([{ adults: 2, children: [] }]); 
		
	setDateRange(null); 
	setFormattedValue('');
		
	setFilterByCity(null); 
	setFilterByCountry(null); 	

    setImageWithError(0);
	
  };
  
}, []); // end useEffect 

React.useEffect(() => {
    if (!contentLoading) {
      (function (d) {
        "use strict";
  
        // Verifica se já existe um elemento myObserver, se não existir, cria um.
        let observerElement = d.querySelector(".myObserver");
        if (!observerElement) {
          observerElement = d.createElement("div");
          observerElement.classList.add("myObserver");
  
          const ref = d.querySelector("#titleBox");
          ref.parentNode.insertBefore(observerElement, ref);
        }
  
        // Remove qualquer observador anterior associado
        if (observerElement.__observerInstance) {
          observerElement.__observerInstance.disconnect();
        }
  
        // Cria um novo IntersectionObserver
        const observer = new IntersectionObserver(
          function (entries) {
            if (entries[0].intersectionRatio === 0) {
              d.querySelector("#titleBox").classList.add("sticky-element");
            } else if (entries[0].intersectionRatio === 1) {
              d.querySelector("#titleBox").classList.remove("sticky-element");
            }
          },
          { threshold: [0, 1] }
        );
  
        // Observa o elemento
        observer.observe(observerElement);
  
        // Armazena a instância do observer no próprio elemento para evitar múltiplos observers
        observerElement.__observerInstance = observer;
        
      })(document);
    }
  }, [contentLoading]);
  

const clearOptions = () => {

	setHostsAndRooms(null); 
	setRooms([{ adults: 2, children: [] }]);	
	setDateRange(null); 
	setFormattedValue('');

	setFilterByCity(null); 
	setFilterByCountry(null); 

}

const breadcrumbItems = [...breadcrumbArray];
 
const handleTimeout = () => {

	setTimeout(function() {

		setLoadingResults(false);

		setAlertTitle("Resultados não encontrados");
		setAlertBody("Infelizmente não encontramos resultados para a busca. Por favor, revise os dados e tente novamente.");
		setAlert(true);	

	},5000);

};
 
const handleFilterTimeout = () => {

	setTimeout(function() {

		setLoadingFiltering(false);

	},3000);

};

const handleCardClick = (id) => {
	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`NAVITAGE TO >>>> /order/hotel/property/${id}`);
	//navigate(`/order/hotel/property/${id}`);
};

const handleCardImageClick = (id,e) => {

	// Avoid onclick event on parent
	e.stopPropagation();

	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`OPEM IMAGE GALLERY FOR PROPERTY ID >>>> ${id}`);
	
	setSelectedItem(id);
	
	setDialog("ImageGalleryZoomViewModal",true)
	//navigate(`/order/hotel/property/${id}`);
};


/**
 * Handles the click event on a room card image.
 *
 * @param {Object} room - The room object containing details of the room.
 * @param {Event} event - The click event object.
 */
const handleCardRoomImageClick = (room, event) => {

    // Avoid onclick event on parent
    event.stopPropagation();
    
    setRoomData(room);	
    setDialog("ImageGalleryRoomViewModal",true);    

};

/**
 * Handles the click event for the room amenities button.
 * Prevents the click event from propagating to the parent element and opens the Room Amenities modal.
 * 
 * @param {Object} room - The room object containing details about the room.
 * @param {Event} e - The click event object.
 */
const handleCardRoomAmenitiesClick = (room,event) => {

	// Avoid onclick event on parent
	event.stopPropagation();
	
    setRoomData(room);
    setDialog("RoomAmenitiesViewModal", true); 

};

/**
 * Handles the click event for the property POI (Point of Interest) button.
 * Prevents the click event from propagating to the parent element and opens the Property POI modal.
 * 
 * @param {Object} room - The room object containing details about the room.
 * @param {Event} e - The click event object.
 */
const handleCardPropertyPOIClick = (room,event) => {

    // Prevent the click event from propagating to the parent element
    event.stopPropagation();
        
    setDialog("PropertyPoiViewModal", true);

};


/* Photo gallery item template */
const itemTemplate = (item) => {
    return (
            <div style={{ position: 'relative', width: '100%' }}>
                {imageLoading && (
                    <div 
                        style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                            color: '#fff', 
                            zIndex: 2 
                        }}
                    >
                        Carregando...
                    </div>
                )}
                <img 
                    src={item.photo_url.replace("max500", "max800")} 
                    style={{ width: '100%', height: 'auto', borderRadius: '1rem', padding: '0rem' }} 
                    onLoad={() => setImageLoading(false)} // Remove o loading quando a imagem carregar
                    onError={handleImageError} // Caso ocorra um erro, remove o loading também
                />
            </div> 
    );   
} 
 
/* Tab header template */
const tabHeaderTemplate = (item,options) => {

    var label = "N/A";

    if (item == 'rooms') {
        label = "Opções de quarto";
    } else if (item == 'location') {
        label = "Localização";
    } else if (item == 'policy') {
        label = "Termos e condições";
    }

    return (
        <div className="flex-row align-items-center justify-center" style={{ padding:"1rem",cursor: 'pointer',color: "#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal", fontWeight: 500,lineHeight: "2.12938rem" }} onClick={options.onClick}>
            <span className="font-bold white-space-nowrap">{label}</span>
        </div>
    );
};


  // Função que será chamada ao retornar após X minutos
  const handleReturnAfterTimeout = () => {

    // Prepare props and data
    var props = {
        setAlertExpire:setAlertExpire,
        setAlertTitleExpire:setAlertTitleExpire,
        setAlertBodyExpire:setAlertBodyExpire,
        setAlertActionExpire:setAlertActionExpire,
    }
    var data = {
        searchResultToken:searchResultToken
    }	
    checkSearchResult(data,props);

};

React.useEffect(() => {
const handleVisibilityChange = () => {
  if (document.visibilityState === "hidden") {
    // Quando a aba fica inativa, salva o tempo atual
    setLastHiddenTime(Date.now());
  } else if (document.visibilityState === "visible") {
    // Quando a aba volta a ficar ativa
    if (lastHiddenTime) {
      const elapsedMinutes = (Date.now() - lastHiddenTime) / (1000 * 60); // Calcula o tempo em minutos
      if (elapsedMinutes >= timeoutMinutes) {
        handleReturnAfterTimeout();
      }
    }
    setLastHiddenTime(null); // Reseta o último tempo ao retornar
  }
};

// Adiciona o listener para detectar mudança de visibilidade
document.addEventListener("visibilitychange", handleVisibilityChange);

return () => {
  // Remove o listener ao desmontar o componente
  document.removeEventListener("visibilitychange", handleVisibilityChange);
};
}, [lastHiddenTime]);


/*const handleImageError = (event) => {
    setImageLoading(false);
    event.target.src = imagePlaceHolder; // Define o placeholder importado como fallback
  };*/

const handleImageError = (event) => {
    setImageLoading(false);
   // event.target.src = imagePlaceHolder; // Define o placeholder importado como fallback
    event.target.style.display = "none"; // Esconde a imagem ao ocorrer um erro

    if (event.target.parentElement) {
        event.target.parentElement.style.display = "none"; // Oculta o div pai
    }    

    setImageWithError((prev) => prev + 1);

};

  return (
	<>
		{(loadingResults && !loadingFiltering) &&
		<>
		<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

				<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>

					<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
						<ProgressSpinner />
						<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Procurando hotéis...Aguarde! </div>
						<div className="formSubTitle">Na DREAM4 você encontra os melhores quartos em hotéis 4 estrelas...</div>		

						<div style={{marginTop:"30px",display:"flex",flexDirectin:"row",justifyContent:"center",alignItems:"cnter",gap:"15px"}}>
							
							<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><div style={{flex:1}}><Countdown startTime={60} onTimeout={handleTimeout} /></div></div>
							
							{showCancelButton &&
								<Button label="Cancelar busca" outlined rounded className="pT10 mR10 headerButton" style={{float:"unset"}} onClick={() => {setLoadingResults(false)}}  />
							}

							</div>	
					</div>

				</div>

		</div>
		</>
		}

		{(!loadingResults && loadingFiltering) &&
			<>
			<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>
	
					<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>
	
						<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
							<ProgressSpinner />
							<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Aplicando filtros...Aguarde! </div>
							<div className="formSubTitle">DREAM4: hotéis 4 estrelas que você pode sonhar...</div>		
	
						</div>
	
					</div>
	
			</div>
			</>
		}		

	
	<div id="navigationSection" style={{width:"100%",margin:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative",borderRadius:0}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >

			<div style={{minHeight:"600px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
				<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
							
					<div style={{flex:1,minHeight:"594px",marginLeft:"auto",marginRight:"auto"}}>
						
					<div className="searchWrapper" style={{padding: "3rem",paddingTop:"1.8rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1.8rem",width: "100%"}}>

                        <div className="backAndsearchFields w100" style={{marginBottom:"1rem"}}>

                                <div className="searchWrapperInput" style={{flex:"1",width:"100%",marginTop:"20px"}}>

                                    <div container style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center",gap:"10px"}}>

                                        <div style={{flex:"1",textAlign:"left"}}>
                                            <Button icon="pi pi-arrow-left" label={searchType == 'location' ? "Voltar para Resultados" : "Fazer nova busca"} text className="pT10 mR10 backButton" onClick={searchType == 'location' ? ()=>{navigate("/order/hotel/searchResult")} : ()=>{navigate("/order")}} style={{color:"#814DE5"}} /> 
                                        </div>                                      

                                        <div style={{flexWrap:"nowrap",flex:"0.7"}}>
                                            <span className="p-float-label">
                                                <Calendar value={dateRange}  minDate={new Date()}  onChange={handleDateChange}  locale="br"  id="rangeDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} footerTemplate={customFooter} ref={calendarRef} />
                                                <label htmlFor="companyEmail">Data de chegada e de saída<span className="requiredField">*</span></label>

                                                {/* Rende input oiver orioginal to show number of nights */}
                                                {dateRange &&
                                                <input 
                                                    type="text" 
                                                    value={formattedValue} 
                                                    readOnly 
                                                    style={{ position: "absolute",top: "1px",left: "4px",height: "95%",width: "86%",border: "none",background: "transparent",pointerEvents: "none",paddingLeft: "1rem",fontSize: "1rem",color: "#495057",padding: ".75rem .75rem",appearance: "none",borderRadius: "6px",backgroundColor: "#FFF", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"}} 
                                                />
                                                }

                                            </span>
                                        </div>
                                        <div style={{flexWrap:"nowrap",flex:"0.4"}}>
                                            <span className="p-float-label">
                                                <InputText id="hosts" autoComplete="new-password" disabled={saving} className="w100" value={hostsAndRooms} onChange={(e) => setHostsAndRooms(e.target.value)} readOnly onClick={(e) => overlayPanelRef.current.toggle(e)}  />

                                                    <OverlayPanel ref={overlayPanelRef}  dismissable={true} showCloseIcon onHide={() => handleCheckSelectedOptions(rooms)}  style={{maxWidth:"18vw",width:"18vw",maxHeight:"65vh"}} >
                                                        <RoomSelector onSelection={handleRoomSelection} setRooms={setRooms} initialRooms={rooms} updateInputValue={handleUpdateInputValue} overlay={true} />
                                                    </OverlayPanel>										

                                                <label htmlFor="companyPhone">Hospedes e quartos <span className="requiredField">*</span></label>
                                            </span>

                                        </div>

                                        <div style={{flexWrap:"nowrap"}}>
                                            <Button label="Atualizar informações" outlined rounded className="pT10 mR10 updateButton" onClick={()=>{updateRetrieveProperty()}} style={{color:"#814DE5",borderColor:"#814DE5"}} />
                                        </div>							
                                        
                                    </div>
                                    
                                    
                                </div>												  
                                    


                        </div>
		
                     
						<div id="titleBox" className="titleBox selectBoxTitleOld animateOld flex-row justify-center align-items-center w100" style={{marginBottom:"0px",color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1",gap:"10px",maxWidth:"1440px"}} >


                            {/* LOADING  */}
                            {(contentLoading) &&
                                <>
                                    <div style={{backgroundColor:"transparent", width:"100%",marginTop:"5px",marginBottom:"5px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row"}}>
                                    
                                        <div style={{flex:1}}>
                                            <Skeleton width="70%" height="5vh" ></Skeleton>
                                        </div>
                                                                                            
                                    </div>
                                                                     
                                </>				
                            }   

                            {/* LOADING  */}
                            {(!contentLoading) &&
                            <>
                                <div style={{textAlign:"left",flex:1}}>


                                    <div className="resultsCard_details__left flex-row justify-start align-items-center" style={{gap:"1rem",flex:1}}>

                                        <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",
                                        fontStyle: "normal",fontWeight: 700,lineHeight: "normal",textAlign:"left"}}>{resultCard.hotel_name}</div>

                                        <div className="resultsCard_details__location" style={{color: "#878787",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>{resultCard.country}, {resultCard.city} {resultCard.neighborhood ? ", "+resultCard.neighborhood : ""}</div>                                        

                                        <div className="resultsCard_details__ratingValue" style={{display: "flex",width: "3.875rem",height: "auto",padding: "0.625rem",flexDirection: "column",justifyContent: "center",alignItems: "center",gap: "0.625rem",borderRadius: "1.8125rem",background: "#00A3FF",color: "#FFF",fontFamily: "Titillium Web",fontSize: "1rem",fontStyle: "normal",fontWeight: 600,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{padronizarDecimal(resultCard.review_rating)}</div>

                                    </div>


                                </div>
                            </>
                            }
       			
						</div>
        

						<div className="navigationContentWrapper flex-row justify-center align-items-start" style={{maxWidth:"1440px",marginTop:"0",width:"100%",flex:1,gap:"1rem"}}>

									<div className="resultsWrapper flex-column justify-center align-items-center" style={{gap:"1rem",flex:1}}>
								
                                        {/* HOTEL DETAILS - START */}
                                        <div className="resultsCard flex-row justify-center align-items-stretch w100" style={{gap:"1rem",padding:"2rem",borderRadius: "1.87rem",background: "#FFFFFF"}}>

                                            {/* LOADING  */}
                                            {(contentLoading) &&
                                                <>
                                                    <div className="w100 flex-row justify-center align-items-stretch" style={{gap:"40px",height:"50vh"}}>
                                                        <div style={{flex:1}}>
                                                            <Skeleton width="100%" height="20rem" ></Skeleton>
                                                            <br />
                                                            <Skeleton width="100%" height="4rem" ></Skeleton>
                                                            <br />
                                                            <Skeleton width="100%" height="4rem" ></Skeleton>                              
                                                        </div>                                              
                                                    </div>
                                                    
                                                    <div className="w100 flex-row justify-center align-items-stretch" style={{gap:"40px",height:"50vh"}}>
                                                        <div style={{flex:1}}>
                                                            <Skeleton width="100%" height="20rem" ></Skeleton>
                                                            <br />
                                                            <Skeleton width="100%" height="4rem" ></Skeleton>
                                                            <br />
                                                            <Skeleton width="100%" height="4rem" ></Skeleton>                              
                                                        </div>
                                                    </div>
                                                </>				
                                            }                                            

                                            {/* LOADING  */}
                                            {(!contentLoading) &&
                                            <>
                                                <div className="flex-column justify-center align-items-center" style={{gap:"2rem",flex:1}}>

                                                    <div className="resultsCard_image" style={{width:"38rem",height:"25rem",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}}>

                                                        <div style={{flex:1}}>
                                                            <Galleria  ref={galleria}   activeIndex={activeIndex} onItemChange={(e) => {setActiveIndex(e.index);setImageLoading(true)}}  circular value={images} style={{/*display:"flex",*/justifyContent:"start",alignItems:"center",borderRadius:"1rem"}} showThumbnails={false}  showItemNavigators showIndicatorsOnItem={false}  item={itemTemplate} />

                                                            <i className="pi pi-search-plus imagePlusIcon applyPointer" onClick={(e)=>handleCardImageClick(resultCard.hotelid_gar,e)}></i>
                                                        </div>                                                    

                                                    </div> 

                                                    <div  className="resultsCard_details__left flex-column justify-start align-items-start w100" style={{gap:"1rem",flex:1,paddingRight:"2.5rem"}}>

                                                        <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                                        fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{resultCard.hotel_name}
                                                       
                                                            {resultCard.distanceToCityCenter && (<div className="resultsCard_details__distance" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>{resultCard.distanceToCityCenter ? (resultCard.distanceToCityCenter < 700 ? (resultCard.distanceToCityCenter+" m do centro") : (resultCard.distanceToCityCenter / 1000).toFixed(2)+" km do centro") : ""}</div>)}                                                            

                                                        
                                                        </div>

                                                        <div className="resultsCard_details__description" style={{color: "#444",fontFamily: "Poppins", fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>
                                                        
                                                         {!descriptionDetail ? resultCard?.property_description?.slice(0,600) : ''}
                                                         {(!descriptionDetail && resultCard?.property_description?.length > 600) && (<span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>setDescriptionDetail(true)}>...Ver mais</span>)}

                                                            {descriptionDetail && 
                                                            <>
                                                                {resultCard.property_description}
                                                            </>
                                                            }

                                                            {descriptionDetail && (<span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>setDescriptionDetail(false)}>Ver menos</span>)}
                                                                                                            
                                                        </div>
                                                    
                                                        <div className="resultsCard_details__amenities">
                                                            <ul style={{listStyleType: "none",textAlign:"left", columns: (resultCard?.amenitiesGroups?.length > 4) ? 3 : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400,columnGap:"60px",margin:0}}>	

                                                            {resultCard?.amenitiesGroups?.slice(0, 5).map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.label}</li>))}
                                                        
                                                            </ul>
                                                        </div>                                                
                                                    

                                                    </div>


                                                </div>

                                                <div className="resultsCard_details flex-row justify-start align-items-stretch" style={{gap:"1rem",flex:1}}>

                                                    <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"1rem",flex:1}}>

                                                        <div>
                                                        
                                                            <div className="imageGallery grid" style={{ width:"100%",display:"flex",justifyContent:"start",alignItems:"start",flexDirection:"row",flexWrap:"wrap",gap:"0.75rem",padding:"0.5rem"}}>
                                                            {
                                                                images && images?.slice(0,11).map((image, index) => {
                                                                    let imgEl = <img src={image.photo_url}  style={{ cursor: 'pointer',width:"100%",height:"100%", objectFit:"cover",objectPosition:"center"}} onClick={
                                                                        () => {setActiveIndex(index); setImageLoading(true); galleria.current.show()}
                                                                    } onError={handleImageError}  />
                                                                    return (
                                                                        <div className={activeIndex == index ? "imageGallery_item selected" : "imageGallery_item"} key={index} style={{borderRadius:"1rem",width: "6.5rem",height:"5rem",overflow:"hidden"}}>
                                                                            {imgEl}
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            {images?.length > 11 &&
                                                            <>
                                                                <div onClick={(e)=>handleCardImageClick(resultCard.hotelid_gar,e)} className="imageGallery_item applyPointer" style={{backgroundColor:"#814DE555",borderRadius:"1rem",/*width: "4.875rem",height:"4.625rem"*/width: "6.5rem",height:"5rem",overflow:"hidden", display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}}>
                                                                            <div className="imagePlusIcon" style={{fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>mais {imageWithError > 0 ? '' : images.length - 11} fotos</div>
                                                                </div>
                                                            </>
                                                            }

                                                            </div>

                                                        </div>
                                                        
                                                        <div style={{padding:"0.5rem",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"1rem"}}>

                                                            <div className="filterMapImage applyPointer" style={{position:"relative",width:"100%",height:"auto",borderRadius:"1rem",display:"flex",alignIitems:"center",justifyContent:"center",overflow:"hidden",position:"relative"}}>
                                                                <img src={locationImage} style={{flex:1,width:"100%",height:"100%"}} className="noSelect" />	

                                                            {/* <Button label="Mostrar no mapa" rounded style={{position:"absolute",left:"50%",bottom:"1rem",transform:"translateX(-50%)"}} className="pT10 mR10 headerButton tColorWhite" onClick={()=>{setDialog("mapviewModal",true)}} />*/}

                                                                <i className="pi pi-search-plus addressPlusIcon" onClick={()=>{setDialog("mapviewModal",true)}}></i>

                                                            </div>

                                                           <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "0.75",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>
                                                                {resultCard.hotel_address ? `${resultCard.hotel_address}, ${resultCard.city} ${resultCard.neighborhood ? ", "+resultCard.neighborhood : ""}, ${resultCard.country} `: ""}
                                                            </div>
                                                        

                                                        </div>


                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"1.5rem",textAlign:"left"}}>

                                                            <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                        lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                                <div style={{flex:1}}>O que há nos arredores</div>
                                                               
                                                                {resultsPois.length > 0 &&
                                                                <>
                                                                    <div className="applyPointer" style={{alignSelf:"end",marginLeft:"0.5rem",color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={(e)=>handleCardPropertyPOIClick(resultCard,e)}>Ver no mapa</div>
                                                                 </>
                                                                }
                                                                
                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

                                                                    {resultsPois.length < 1 &&
                                                                        <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Nenhum ponto de interesse encontrado</div>
                                                                        </div>
                                                                    }


                                                                    {resultsPois.length > 0 && resultsPois.slice(0,5).map((item, index) =>{

                                                                        return (
                                                                            <div key={item.poiid_ppn} className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                            <div>                                                                     
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                                </svg>   
                                                                            </div>
                                                                            <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.poi_name}</div>
                                                                            <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.distancia ? item.distancia.toFixed(2)+" km" : ""}</div>
                                                                            </div>
                                                                        );

                                                                    })}

                                                            </div>

                                                        </div> 


                                                    </div>

                                                </div>
                                            </>
                                            }

                                        </div>
                                        {/* HOTEL DETAILS - END */}                         

                                        {/* ROOMS AND TABS - START */}
                                        <div id="tabsSection" className="resultsCard flex-row justify-center align-items-stretch w100" style={{gap:"1rem",padding:"0rem",borderRadius: "1.87rem",background: "transparent", scrollMarginTop: 150}}>

                                            <div className="flex-column justify-center align-items-center" style={{flex:1}}>

                                            <TabView className="customTab w100" style={{flex:1}} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>

                                                <TabPanel key="tab1" headerTemplate={(options)=>tabHeaderTemplate('rooms',options)} headerClassName="customTab flex-row align-items-center justify-center w100" style={{backgroundColor:"transparent !important"}}>

                                                <Toast ref={toast} position="top-center" />
                                                <div className="resultsWrapper flex-column justify-center align-items-center w100" style={{paddingTop:"1rem",backgroundColor:"transparent",gap:"1rem",flex:1}}>

                                                    {!contentLoading && sortRooms(resultsRooms).map((item, index) =>{

                                                    const valueAdds = item?.["value-adds"]?.["value-add"]; // Safe access

                                                    // Função para verificar se um código específico está presente
                                                    const hasCode = (code) => {
                                                        if (!valueAdds) return false;
                                            
                                                        // Verifica se é um array
                                                        if (Array.isArray(valueAdds)) {
                                                        return valueAdds.some((valueAdd) => valueAdd.code === code);
                                                        }
                                            
                                                        // Caso seja um objeto único
                                                        return valueAdds.code === code;
                                                    };

                                                    
                                            
                                                    // const hasFreeCancellation = hasCode("free_cancellation");
                                                    const hasFreeCancellation = item['cancellation-deadline'] ? item['cancellation-deadline'] : false;
                                                    const hasFreeBreakfast = hasCode("free_breakfast");

                                                   // console.log('[item]----------------------------------------------> '+JSON.stringify(item,null,2));

                                                        return (
                                                            <div key={item.room['room-id']+'-'+index} className="resultsCard flex-row justify-center align-items-stretch w100" style={{height:"17rem",gap:"1.5rem",padding:"1.5rem",borderRadius: "2.5rem",background: "#FFFFFF",position:"relative"}}>


                                                                {/* Room person selector START */}
                                                                <div id={`cardPanel_${item.room['room-id']+'-'+index}`} className={visiblePanel === item.room['room-id']+'-'+index ? 'highIndex cardPanel flex-row justify-center align-items-stretch fade-in' : 'lowIndex cardPanel flex-row justify-center align-items-stretch fade-out'} style={{top:"0",right:"0",position:"absolute",width:"20rem",height:"100%",borderRadius:"0rem 2.5rem 2.5rem 0rem",backgroundColor:"#FFF",boxShadow:"0px 0px 84px 0px rgba(56, 102, 128, 0.16)"}}>

                                                                    <Button id={`close_${item.room['room-id']+'-'+index}`} icon="pi pi-times" rounded  onClick={handleClose} style={{fontsize:"0.45",position:"absolute",top:"-1rem",left:"-1rem",zIndex:"9999",height:"1rem",width:"1rem",padding:"1rem"}} />
    
                                                                    <div className="flex-column align-items-stretch" style={{gap:"0.5rem",padding:"1.5rem",flex:1,justifyContent:"space-between"}}>

                                                                        <div className="flex-column justify-center align-items-center" style={{gap:"0.2rem"}}>
                                                                            <div style={{"color":"#1D2939","textAlign":"center","fontFamily":"Poppins","fontSize":"0.9rem","fontStyle":"normal","fontWeight":"500","lineHeight":"120%"}}>Selecione a quantidade de pessoas no quarto</div>
                                                                            {/*<div style={{"color":"#878787","textAlign":"center","fontFamily":"Poppins","fontSize":"0.775rem","fontStyle":"normal","fontWeight":"400","lineHeight":"normal"}}>(quarto acomoda até 3 pessoas)</div> */}    
                                                                        </div>
                                                                                                                                                
                                                                        <div>

                                                                        <div className="room-controls">
                                                                            <div className="room-each-control">
                                                                                <h4 className="prevent-select">Adultos</h4>
                                                                                <div className="control-buttons">
                                                                                <Button 
                                                                                    icon="pi pi-minus" 
                                                                                    onClick={handleRemoveAdult} 
                                                                                    disabled={selectedGuests.adults === 1} 
                                                                                />
                                                                                <span className="quantity prevent-select">{selectedGuests.adults}</span>
                                                                                <Button 
                                                                                    icon="pi pi-plus" 
                                                                                    onClick={handleAddAdult} 
                                                                                    /*disabled={!canAddAdult()}*/
                                                                                    disabled={selectedGuests.adults >= getMaxAdultsForCurrentRoom()}  
                                                                                />
                                                                                </div>
                                                                            </div>
                                                                            <div className="room-each-control">
                                                                                <h4 className="prevent-select">Crianças</h4>
                                                                                <div className="control-buttons">
                                                                                    <Button 
                                                                                        icon="pi pi-minus" 
                                                                                        onClick={() => handleRemoveChild(selectedGuests.children.length - 1)} 
                                                                                        disabled={selectedGuests.children.length === 0} 
                                                                                    />
                                                                                    <span className="quantity prevent-select">{selectedGuests.children.length}</span>
                                                                                    <Button 
                                                                                        icon="pi pi-plus" 
                                                                                        onClick={handleAddChild} 
                                                                                        disabled={!canAddChild()} 
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="child-controls-wrapper">
                                                                        {selectedGuests.children.map((child, childIndex) => (
                                                                            <div key={childIndex} className="child-controls">
                                                                                {/*<Dropdown
                                                                                    value={selectedChildAges[childIndex] || null}
                                                                                    options={getAvailableAges(childIndex).map(age => ({ label: `${age} anos`, value: age }))}
                                                                                    onChange={(e) => handleChildAgeChange(childIndex, e.value)}
                                                                                    placeholder="Idade"
                                                                                />*/}
                                                                                <Dropdown
                                                                                    value={selectedChildAges[childIndex] || null}
                                                                                    options={getAvailableAges(childIndex).map(age => ({ label: `${age} anos`, value: age }))}
                                                                                    onChange={(e) => handleChildAgeChange(childIndex, e.value)}
                                                                                    placeholder="Idade"
                                                                                />                                                                         
                                                                            </div>
                                                                        ))}
                                                                        </div>

                                                                        </div>
                                                                        
                                                                        <div>

                                                                            <Button label="Confirmar seleção" rounded className="pT10 tColorWhite" style={{fontsize:"0.85",float:"unset"}}  onClick={() => confirmRoomSelection(item, index)} loading={pickingRoomLoading[item.room['room-id'] + '-' + index] || false} />

                                                                        </div>


                                                                    </div>
                                                                    

                                                                </div>
                                                                {/* Room person selector END */}

                                                                <div onClick={(e)=>handleCardRoomImageClick(item,e)}  className="resultsCard_image" style={{width:"12rem",height:"12rem",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"1.5rem",position:"relative"}}>

                                                                    <img src={(item.extraDetails?.images && item.extraDetails?.images.length > 0) ? item.extraDetails?.images[0].urls.default : imagePlaceHolder} style={{flex:1}} />

                                                                    <i className="pi pi-search-plus imagePlusIcon applyPointer"></i>

                                                                </div>
                                                               
                                                                <div className="resultsCard_details flex-row justify-start align-items-stretch" style={{gap:"1rem",flex:1}}>

                                                                    <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"0.3rem",flex:1}}>

                                                                        <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                                                        fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{item.room.title}</div>

                                                                        <div className="resultsCard_details__amenities">
                                                                            <ul style={{listStyleType: "none",textAlign:"left", columns: (item.extraDetails.amenities.length > 4) ? 1 : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>																	
                                                                            {item.extraDetails.amenities.slice(0, 5).map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.name.en}</li>))}

                                                                            {item.extraDetails.amenities.length > 5 &&
                                                                            <li style={{marginTop:"0.5rem",marginLeft:"0.5rem"}}> <span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={(e)=>handleCardRoomAmenitiesClick(item,e)}>Ver tudo</span></li>
                                                                            }
  
                                                                            </ul>
                                                                        </div>

                                                                    </div>

                                                                    <div  className="resultsCard_details__right flex-column justify-start align-items-start" style={{gap:"1rem",flex:1.1}}>

                                                                        <div className="flex-column justify-center align-items-center resultsCard_details__rating w80" style={{display: "flex",justifyContent: "start",alignItems: "start",flex:1,gap:"0.5rem"}}>

                                                                            <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Política de cancelamento</div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="w100 ">
                                                                                <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

                                                                                {hasFreeCancellation && <span className="label" style={{color:"green",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> Cancelamento Gratuito <label style={{color:"#878787"}}>até {moment(item['cancellation-deadline']).businessSubtract(2, 'days').format('DD/MM/YYYY')}</label></span>}
                                                                           
                                                                                </div>
                                                                            </div>  

                                                                        </div>

                                                                        <div className="flex-column justify-center align-items-center resultsCard_details__rating w80" style={{display: "flex",justifyContent: "start",alignItems: "start",flex:1}}>

                                                                            <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="w100 ">

                                                                                <div className="flex-column justify-center align-items-start w100" style={{gap:"1rem"}}>

                                                                                    {hasFreeBreakfast && <span className="label" style={{color:"green",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> Café da manhã incluído</span>}

                                                                                    {!hasFreeBreakfast && <span className="label" style={{color:"gray",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-times"></i> Nenhuma opção extra</span>}                                                                                
                                                                                </div>
                                                                             
                                                                            </div> 
                                                                            

                                                                        </div>

                                                                    </div>

                                                                    <div  className="resultsCard_details__right flex-column justify-start align-items-center" style={{gap:"1rem",flex:1}}>

                                                                        <div className="resultsCard_details__rating w100" style={{display: "flex",justifyContent: "end",alignItems: "start",flex:1}}>
                                                                  
                                                                        </div>


                                                                        <div className="resultsCard_details__prices flex-column justify-start align-items-center w100" style={{gap:"0.5rem",textAlign:"right"}}>

                                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{/*Diária: {formatNumberCurrency(item.total_with_markup / dateRangeDays,item.display_price.currency)}  <br />*/}   {dateRangeDays > 1 ? `${dateRangeDays} diárias` : `${dateRangeDays} diária`} {/*, {rooms.length > 1 ? `${rooms.length} quartos` : `${rooms.length} quarto`} */} <br /> {hostsAndRoomsLabel}</div>
                                                                            
                                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #00A3FF",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.12938rem"}}><span style={{fontSize: "0.9375rem"}}>Total </span> 
                                                                                
                                                                                {formatNumberCurrency(exchangeRateInitials == "BRL" ? item.totalWithMarkup * usdExchangeRate : item.totalWithMarkup,exchangeRateInitials)}
                                                                                
                                                                                </div>	

                                                                                <div style={{marginTop:"1rem",textAlign:"right",width:"100%"}}>

                                                                                    {(parseInt(item.roomsLeft) < 4 && item.roomsLeft != "")&&
                                                                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "red",fontFamily: "Titillium Web",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem",marginBottom:"0.5rem"}}>* Apenas {item.roomsLeft} quartos disponíveis</div>
                                                                                    }    

                                                                                    <Button id={`select_${item.room['room-id']+'-'+index}`}  label="Selecionar opção" rounded className="pT10 tColorWhite headerButton" onClick={rooms.length > 1 ? () => {handleSelect(item.room['room-id']+'-'+index)} : () => confirmRoomSelection(item,index)} disabled={roomsButtonDisabled} loading={pickingRoomLoading[item.room['room-id'] + '-' + index] || false} style={{fontsize:"0.85",float:"unset"}} />     
                                                                                </div>	 
                                                                                
                                                                        </div>

                                                                    </div>
                                                                    
                                                                </div>

                                                            </div>

                                                        );
                                                    })}

                                                    {/* LOADING ACTION  */}
                                                    {contentLoading &&
                                                        <>						
                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>
                                                        </>				
                                                    }										


                                                    </div>

                                                </TabPanel>

                                                <TabPanel key="tab2" headerTemplate={(options)=>tabHeaderTemplate('location',options)} headerClassName="customTab flex-row align-items-center justify-center w100">
                                               
                                                    <div className="flex-row justify-center align-items-stretch w100" style={{padding:"2rem",backgroundColor:"transparent",gap:"3rem",flex:1}}>

                                                        <div className="flex-column justify-start align-items-center w100" style={{gap: '1rem',textAlign: 'left',flex: '0.7 1 0%',maxHeight: '55vh',overflowY: 'scroll',padding: '15px'}}>

                                                            <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                        lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                                <div style={{flex:1}}>Endereço</div>
                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem",marginBottom:"1rem"}}>

                                                                <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",width:"100%",texxtAlign:"left"}}>{resultCard.hotel_address ? `${resultCard.hotel_address}, ${resultCard.city} ${resultCard.neighborhood ? ", "+resultCard.neighborhood : ""}, ${resultCard.country} `: ""}</div>                                                                   

                                                            </div> 


                                                            <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                        lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                                <div style={{flex:1}}>O que há nos arredores</div>

                                                                {resultsPois.length > 0 &&
                                                                <>
                                                                    <div className="applyPointer" style={{alignSelf:"end",marginLeft:"0.5rem",color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={(e)=>handleCardPropertyPOIClick(resultCard,e)}>Ver no mapa</div>
                                                                 </>
                                                                }

                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

                                                                    {resultsPois.length < 1 &&
                                                                        <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Nenhum ponto de interesse encontrado</div>
                                                                        </div>
                                                                    }

                                                                    {resultsPois.length > 0 && resultsPois.map((item, index) =>{

                                                                        return (
                                                                            <div key={item.poiid_ppn} className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}} onClick={() => setSelectedPoi(item)}>
                                                                            <div>                                                                     
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                                </svg>   
                                                                            </div>
                                                                            <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.poi_name}</div>
                                                                            <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.distancia ? item.distancia.toFixed(2)+" km" : ""}</div>
                                                                            </div>
                                                                        );

                                                                    })}

                                                            </div> 

                                                        </div> 

                                                        <div className="flex-column justify-center align-items-center w100" style={{flex:1,height:"50vh",borderRadius:"10px",overflow:"hidden"}}>

                                                            {!center && 1 == 2 &&
                                                                    <div style={{backgroundColor:"transparent",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",padding:"30px"}}>

                                                                        <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"20vw",textAlign:"center"}}>
                                                                            <ProgressSpinner />
                                                                            <div className="formTitle" style={{marginBottom:"15px",marginTop:"15px",fontSize:"2rem"}}>Carregando mapa...Aguarde! </div>
                                                                        </div>

                                                                    </div>           
                                                            }

                                                            {center &&
                                                            <div style={{ flex:1,height: 'inherit', width: 'inherit' }}>
                                                            <APIProvider apiKey={apiKey}>
                                                                <Map
                                                                defaultZoom={12}
                                                                /*center={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}}*/
                                                                defaultCenter={center}
                                                                gestureHandling={'greedy'}
                                                                disableDefaultUI={false}
                                                                fullscreenControlOptions={false}
                                                                mapTypeControl={false}
                                                                streetViewControl={false}
                                                                fullscreenControl={false}
                                                                > 
                                                                <Marker position={{lat: parseFloat(markerCenter.lat), lng:parseFloat(markerCenter.lng)}} />

                                                                {resultsPois.map((poi) => (
                                                                <Marker key={poi.poiid_ppn} position={{ lat: parseFloat(poi.latitude), lng: parseFloat(poi.longitude) }} 
                                                                    onClick={() => setSelectedPoi(poi)}
                                                                    icon={{
                                                                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"/*,
                                                                        scaledSize: new window.google.maps.Size(40, 40)*/
                                                                    }}
                                                                />
                                                                
                                                        ))}


                                                        {selectedPoi && (
                                                            <InfoWindow options={{pixelOffset: new window.google.maps.Size(0, -40)}} position={{ lat: parseFloat(selectedPoi.latitude), lng: parseFloat(selectedPoi.longitude) }} 
                                                                onCloseClick={() => setSelectedPoi(null)}>
                                                                <div>
                                                                    <h4 style={{marginBottom:"2px",paddingBottom:"2px"}}>{selectedPoi.poi_name}</h4>
                                                                    <h4 style={{color:"silver",marginTop:"2px",paddingTop:"2px"}}>{selectedPoi.category}</h4>
                                                                </div>
                                                            </InfoWindow>
                                                        )}                                                           


                                                                </Map>
                                                            </APIProvider>
                                                            </div>
                                                            }

                                                        </div>




                                                    </div>

                                                </TabPanel>

                                                <TabPanel key="tab3" headerTemplate={(options)=>tabHeaderTemplate('policy',options)} headerClassName="customTab flex-row align-items-center justify-center w100">

                                                    <div className="flex-row justify-start align-items-stretch w100" style={{padding:"2rem",backgroundColor:"transparent",gap:"2rem",flex:1,flexWrap:"wrap"}}>

                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Geral</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Todas as reservas estão sujeitas à disponibilidade, e as tarifas podem variar dependendo das políticas e preços do hotel. Restrições ou condições especiais, como políticas de cancelamento e reembolso, podem ser aplicadas a determinadas tarifas. Por favor, revise os detalhes específicos de cada reserva antes de confirmá-la.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Antecedência e disponibilidade através da plataforma</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Reservas só podem ser feitas com antecedência mínima de 3 dias corridos da data do serviço. Recomendamos avisar nossa central de atendimento, assim que uma reserva for realizada, para que possamos acompanhar o processo.
                                                            Pedidos com menos de três dias corridos de sua utilização e condições excepcionais de pagamento. Reservas solicitadas com prazo inferior a três dias antes do check-in e/ou pagamentos realizados com cartão de terceiros não poderão ser efetuados pela plataforma. Nestes casos, é necessário entrar em contato com a central de atendimento.
                                                        </div>

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Pagamentos através da plataforma</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Os pagamentos através da plataforma deverão ser feitos exclusivamente através do cartão de crédito do titular do serviço (passageiro líder), respeitando a grafia de seu nome conforme inserido na reserva e com a data de vencimento do cartão no formato DD/AAAA para que o pagamento seja aprovado.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Preços</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            As tarifas exibidas estão sujeitas a alterações e podem incluir condições especiais impostas pelos hotéis. Impostos, taxas e cobranças adicionais podem ser aplicados e serão informados no momento da cotação.
                                                            A tarifa para ocupação dupla é garantida, salvo indicação contrária. Acomodações para mais de dois hóspedes podem implicar cobranças adicionais, pagáveis diretamente ao hotel.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Requisitos de Check-In</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Um documento de identificação válido e um cartão de crédito ou débito em nome do titular da reserva são necessários no check-in. A idade mínima para check-in será especificada no momento da reserva.
                                                            Os cartões de crédito ou débito podem ser pré-autorizados para cobranças incidentais, e o valor exigido pode variar de acordo com o hotel.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Cancelamentos e Alterações</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            As políticas de cancelamento e alteração variam de acordo com o hotel e o tipo de reserva. Algumas reservas podem ter multas associadas em caso de cancelamento. Consulte a política de cancelamento fornecida durante o processo de reserva para mais detalhes.
                                                            Vale ressaltar que não trabalhamos com reservas "não reembolsáveis".
                                                            O não comparecimento na primeira noite da reserva, que não tenha sido previamente avisado, pode resultar no cancelamento das noites restantes, sem direito a reembolso.
                                                            Para cancelamentos realizados com pagamento via cartão de crédito, será aplicada uma taxa administrativa de 3,5%.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Necessidades Especiais e Solicitações</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Caso possua necessidades especiais (ex.: acomodações para deficiência ou preferências de quarto), entre em contato diretamente com nossa equipe de suporte após a reserva para tentar garantir que seus requisitos possam ser atendidos. Nossa plataforma não garante essas solicitações.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Impostos e Taxas</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            As informações sobre impostos e taxas incluem encargos aplicáveis ao hotel, como impostos de ocupação, impostos sobre vendas e outras cobranças exigidas por lei. Adicionalmente, alguns hotéis podem cobrar taxas obrigatórias, como, por exemplo, taxas de turismo e resort, que devem ser quitadas diretamente no momento do check-out.
                                                        </div> 

                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Isenção de Responsabilidade</div> 
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left",marginBottom:"1rem"}}>
                                                            Nossa plataforma atua como facilitadora de reservas e não se responsabiliza por eventuais discrepâncias nos serviços ou comodidades oferecidos pelo hotel, que ficam a critério da administração do mesmo. No entanto, nossa equipe está sempre à disposição e fará o possível para intermediar e atender às solicitações dos clientes, buscando alcançar o resultado desejado.
                                                            Todas as reservas estão sujeitas aos termos e condições do respectivo hotel.
                                                            Ao concluir uma reserva em nosso site, você reconhece e aceita estes termos e condições.
                                                            Para dúvidas ou assistência adicional, entre em contato com nossa equipe de suporte.
                                                        </div> 
                                                    </div>

                                                        {/*[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item, index) =>{ 
                                                            
                                                            if (index === 0) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Política de cancelamento</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Each room in this reservation is subject to the following cancellation policy: Cancellations before 01/07/2025, 10:00 PM (Europe/Lisbon) are fully refundable. Bookings cancelled after 01/07/2025, 10:00 PM (Europe/Lisbon) are subject to a fee of 274.16 USD. There is no refund for no-shows, early checkouts, or cancellations after 01/15/2025, 10:00 AM (Europe/Lisbon).</div>
                                                                    </div>
                                                                )
                                                            } 
                                                           else if (index === 1) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Taxas cobradas no local</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Each room in this reservation is subject to the following cancellation policy: Cancellations before 01/07/2025, 10:00 PM (Europe/Lisbon) are fully refundable. Bookings cancelled after 01/07/2025, 10:00 PM (Europe/Lisbon) are subject to a fee of 274.16 USD. There is no refund for no-shows, early checkouts, or cancellations after 01/15/2025, 10:00 AM (Europe/Lisbon).</div>
                                                                    </div>
                                                                )
                                                            } 
                                                           else if (index === 2) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Instruções de checkin</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Guests are required to show a photo ID and credit card upon check-in. Please note that all Special Requests are subject to availability and additional charges may apply. In response to the coronavirus (COVID-19), additional safety and sanitation measures are in effect at this property. Food and beverage services at this property may be limited or unavailable due to the coronavirus (COVID-19). Due to the coronavirus (COVID-19), this property is taking steps to protect the safety of guests and staff. Certain services and amenities may be reduced or unavailable as a result. Due to the coronavirus (COVID-19), this property has reduced reception and service hours. In accordance with government guidelines to minimize transmission of the coronavirus (COVID-19), this property may request additional documentation from guests to validate identity, travel itinerary, and other relevant info on dates where such guidelines exist. Spa and gym facilities at this property are unavailable due to the coronavirus (COVID-19). Due to coronavirus (COVID-19), this property adheres to strict physical distancing measures. Due to the coronavirus (COVID-19), make sure you're booking this property in accordance with the destination's local government guidelines, including (but not limited to) the purpose of travel and maximum group size. Due to the coronavirus (COVID-19), wearing a face mask is mandatory in all indoor common areas.</div>
                                                                    </div>
                                                                )
                                                            } else {
                                                            return(
                                                                <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                    <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Regra ou Taxa</div>
                                                                    <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Lorem ipsum dolor sit amet consectetur. Arcu purus vitae bibendum sit dignissim</div>
                                                                </div>
                                                            )
                                                            }

                                                        }

                                                        )*/}
                                                                                                                                       
                                                    </div>
                                                </TabPanel>
                                            </TabView>

                                            </div>

                                        </div>                                
                                         {/* ROOMS AND TABS - END */}


									</div>
                                    
                                    {/* RIGHT COLUMN WITH CART DETAILS*/}
									<div className="filterWrapper flex-column justify-center align-items-start" style={{width:"24rem",height:"auto",gap:"1.5rem"/*,position:"sticky",top:"90px"*/}}>

										<div className="filterOptions flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius:"1rem",backgroundColor:"#FFFFFF",padding:"1rem",gap:"2rem"}}>

											<div className="filterOptions_title w100" style={{color:"#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 500,lineHeight: "2.12938rem",textAlign:"left",marginTop:"0.5rem"}}>Resumo das seleções</div>

                                            {/* LOADING  */}
                                            {(contentLoading) &&
                                                <>
                                                    <div style={{backgroundColor:"transparent", width:"100%",marginTop:"5px",marginBottom:"5px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row"}}>
                                                    
                                                        <div style={{flex:1}}>
                                                            <Skeleton width="100%" height="5vh" ></Skeleton><br />
                                                            <Skeleton width="100%" height="5vh" ></Skeleton><br />
                                                            <Skeleton width="100%" height="5vh" ></Skeleton><br />
                                                            <Skeleton width="100%" height="5vh" ></Skeleton><br />      
                                                            <Skeleton width="100%" height="5vh" ></Skeleton>
                                                            
                                                        </div>
                                                                                                            
                                                    </div>
                                                                                    
                                                </>				
                                            }   	

                                            {/* LOADED  */}
                                            {(!contentLoading) &&
                                            <>
                                                <div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"0.5rem"}}>

                                                    <div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
                                                    
                                                        <div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{gap:"1rem",width:"100%"}}>

                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Período</div>
                                                                <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>
                                                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                        <span>De:</span>
                                                                        <span>{dateRange.length > 0 ? moment(dateRange[0]).format("DD/MM/YY (ddd)") : '-'}</span>
                                                                    </div>
                                                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                        <span>Até:</span>
                                                                        <span>{dateRange.length > 0 ? moment(dateRange[1]).format("DD/MM/YY (ddd)") : '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                                                                    
                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Diárias</div>
                                                                <div style={{flex:1.5,color: "#475467",textAlign: "right",fontSize: "0.9375rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{dateRangeDays} noites</div>
                                                            </div>
                                                            
                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                                <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>{hostsLabel}</div>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Quartos</div>
                                                                <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>{roomsLabel}</div>
                                                            </div>
                                                                                                                                                                            

                                                        

                                                        </div>
                                                    
                                                    </div>

                                                </div>

											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"0.5rem"}}>

                                                {/* NO ROOM SELECTION */}
                                                {selectedRooms.length == 0 &&
                                                <>
                                                    <div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center applyPointer" style={{width:"100%",gap:"0.5rem"}} onClick={()=>{setActiveTabIndex(0);setTimeout(function() {handleClickScroll('tabsSection')},700)}}>
                                                    
                                                        <div className="flex-row justify-center align-items-stretch" style={{gap:"0.5rem"}}>
                                                            <div>     
                                                            <svg height="34" viewBox="0 0 60 60" width="34" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs">
                                                            <g width="100%" height="100%" transform="matrix(1,0,0,1,0,0)">
                                                            <g id="Page-1" fill="none" fillRule="evenodd">
                                                                <g id="087---Tick-Boxes" fillRule="nonzero">
                                                                <g id="Icons_copy_2" transform="translate(1 1)">
                                                                    <rect id="Rectangle-path" fill="#f2ecff" height="18" rx="3" width="18" fillOpacity="1" data-original-color="#e8edfcff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m15 0h-3c1.6568542 0 3 1.34314575 3 3v12c0 1.6568542-1.3431458 3-3 3h3c1.6568542 0 3-1.3431458 3-3v-12c0-1.65685425-1.3431458-3-3-3z" fill="#dbc8ff" fillOpacity="1" data-original-color="#cad9fcff" stroke="none" strokeOpacity="1" />
                                                                    <rect id="Rectangle-path" fill="#f2ecff" height="4" rx="2" width="33" x="22" y="3" fillOpacity="1" data-original-color="#e8edfcff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m53 3h-3c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h3c1.1045695 0 2-.8954305 2-2s-.8954305-2-2-2z" fill="#dbc8ff" fillOpacity="1" data-original-color="#cad9fcff" stroke="none" strokeOpacity="1" />
                                                                    <rect id="Rectangle-path" fill="#f2ecff" height="4" rx="2" width="21" x="22" y="11" fillOpacity="1" data-original-color="#e8edfcff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m41 11h-3c1.1045695 0 2 .8954305 2 2s-.8954305 2-2 2h3c1.1045695 0 2-.8954305 2-2s-.8954305-2-2-2z" fill="#dbc8ff" fillOpacity="1" data-original-color="#cad9fcff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m57.705 53.6-2.052 2.052-2.053 2.053c-.393245.3931826-1.030755.3931826-1.424 0l-10.9-10.9-4.085 6.815c-.1586025.2623217-.4556945.407966-.7601972.3726738-.3045026-.0352922-.5604056-.2450292-.6548028-.5366738l-7.726-24.14c-.1149176-.359118-.0195846-.7523459.2470347-1.0189653.2666194-.2666193.6598473-.3619523 1.0189653-.2470347l24.14 7.726c.2916446.0943972.5013816.3503002.5366738.6548028.0352922.3045027-.1103521.6015947-.3726738.7601972l-6.82 4.082 10.9 10.9c.1903974.1882996.2979542.4446798.2988925.7124615.0009382.2677817-.1048193.5249092-.2938925.7145385z" fill="#dcd4eb" fillOpacity="1" data-original-color="#a4c2f7ff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m57.705 52.177-10.9-10.9 6.819-4.082c.2623217-.1586025.407966-.4556945.3726738-.7601972-.0352922-.3045026-.2450292-.5604056-.5366738-.6548028l-24.144-7.73c-.4941559-.15787-1.0269816.0883502-1.227.567l22.367 7.159c.2916446.0943972.5013816.3503002.5366738.6548028.0352922.3045027-.1103521.6015947-.3726738.7601972l-4.62 2.765c-.5285047.3166894-.8815473.8592945-.9569582 1.4707866-.0754108.6114921.1352281 1.223612.5709582 1.6592134l9.09 9.091c.3931826.393245.3931826 1.030755 0 1.424l-2.052 2.052-1.264 1.264.788.788c.393245.3931826 1.030755.3931826 1.424 0l2.052-2.052 2.053-2.053c.3925165-.3931303.3925165-1.0298697 0-1.423z" fill="#ac92de" fillOpacity="1" data-original-color="#7facfaff" stroke="none" strokeOpacity="1" />
                                                                    <rect id="Rectangle-path" fill="#f2ecff" height="18" rx="3" width="18" y="25" fillOpacity="1" data-original-color="#e8edfcff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m15 25h-3c1.6568542 0 3 1.3431458 3 3v12c0 1.6568542-1.3431458 3-3 3h3c1.6568542 0 3-1.3431458 3-3v-12c0-1.6568542-1.3431458-3-3-3z" fill="#dbc8ff" fillOpacity="1" data-original-color="#cad9fcff" stroke="none" strokeOpacity="1" />
                                                                </g>
                                                                <g id="Icons_copy" fill="#fff" transform="translate(1 1)">
                                                                    <path id="Shape" d="m0 15c0 1.6568542 1.34314575 3 3 3h3c-1.65685425 0-3-1.3431458-3-3v-12c0-1.65685425 1.34314575-3 3-3h-3c-1.65685425 0-3 1.34314575-3 3z" fill="#ffffff" fillOpacity="1" data-original-color="#ffffffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m0 40c0 1.6568542 1.34314575 3 3 3h3c-1.65685425 0-3-1.3431458-3-3v-12c0-1.6568542 1.34314575-3 3-3h-3c-1.65685425 0-3 1.3431458-3 3z" fill="#ffffff" fillOpacity="1" data-original-color="#ffffffff" stroke="none" strokeOpacity="1" />
                                                                </g>
                                                                <g id="Icons">
                                                                    <path id="Rectangle-path" d="m0 4h2v8h-2z" fill="#ffffff" fillOpacity="1" data-original-color="#ffffffff" stroke="none" strokeOpacity="1" />
                                                                    <g fill="#428dff">
                                                                    <path id="Shape" d="m49.415 42.473 5.731-3.431c.5933584-.3646836.9204195-1.0411757.8377097-1.7327155-.0827098-.6915399-.5600838-1.2718196-1.2227097-1.4862845l-24.14-7.723c-.714829-.2280275-1.4971539-.0380682-2.027814.4923815-.5306602.5304496-.7209299 1.3126991-.493186 2.0276185l7.725 24.138c.2054563.664559.7840151 1.145057 1.475 1.225.7012473.0912379 1.3898028-.2422381 1.753-.849l3.423-5.719 10 10c.3745302.3750467.8829693.585547 1.413.585.5319815.0014876 1.0423657-.2103021 1.417-.588l4.1-4.1c.3765915-.3764129.5881735-.8870455.5881735-1.4195s-.211582-1.0430871-.5881735-1.4195zm8.585 11.42-4.116 4.107-10.904-10.9c-.1874927-.1875494-.4418052-.2929434-.707-.293-.0411241.0001749-.0821997.0028465-.123.008-.3055126.0377124-.5766308.2140315-.735.478l-3.833 6.4-7.571-23.693 23.681 7.579-6.4 3.834c-.264825.1584245-.4416723.4302434-.4792028.7365473-.0375306.3063038.0684601.6127781.2872028.8304527l10.9 10.912z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m4 45h12c2.209139 0 4-1.790861 4-4v-6.34c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v6.34c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-12c0-1.1045695.8954305-2 2-2h12c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-12c-2.209139 0-4 1.790861-4 4v12c0 2.209139 1.790861 4 4 4z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m22.293 24.293-12.293 12.293-3.293-3.293c-.39237889-.3789722-1.01608478-.3735524-1.40181858.0121814-.38573379.3857338-.39115363 1.0094397-.01218142 1.4018186l4 4c.39049985.3903819 1.0235001.3903819 1.414 0l13-13c.3789722-.3923789.3735524-1.0160848-.0121814-1.4018186s-1.0094397-.3911536-1.4018186-.0121814z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m1.02 9c-.55228475.00276142-1.00223858-.44271525-1.005-.995s.44271525-1.00223858.995-1.005h.01c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m4 20h12c2.209139 0 4-1.790861 4-4v-12c0-2.209139-1.790861-4-4-4h-12c-2.209139 0-4 1.790861-4 4 0 .35726559.19059892.68739261.5.8660254.30940108.1786328.69059892.1786328 1 0 .30940108-.17863279.5-.50875981.5-.8660254 0-1.1045695.8954305-2 2-2h12c1.1045695 0 2 .8954305 2 2v12c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-4c0-.5522847-.44771525-1-1-1s-1 .4477153-1 1v4c0 2.209139 1.790861 4 4 4z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m57 6c0-1.65685425-1.3431458-3-3-3h-29c-1.6568542 0-3 1.34314575-3 3s1.3431458 3 3 3h29c1.6568542 0 3-1.34314575 3-3zm-33 0c0-.55228475.4477153-1 1-1h29c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-29c-.5522847 0-1-.44771525-1-1z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    <path id="Shape" d="m45 14c0-1.6568542-1.3431458-3-3-3h-17c-1.6568542 0-3 1.3431458-3 3s1.3431458 3 3 3h17c1.6568542 0 3-1.3431458 3-3zm-21 0c0-.5522847.4477153-1 1-1h17c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-17c-.5522847 0-1-.4477153-1-1z" fill="#8c42ff" fillOpacity="1" data-original-color="#428dffff" stroke="none" strokeOpacity="1" />
                                                                    </g>
                                                                </g>
                                                                </g>
                                                            </g>
                                                            </g>
                                                            </svg>
                                                            </div>
                                                            <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>
                                                                {rooms.length == 1 && (<>Selecione uma opção de quarto</>)}
                                                                {rooms.length > 1 && (<>Selecione as opções de quarto</>)}
                                                            </div>
                                                        </div>                                                      

                                                    </div>
                                                </>
                                                }

                                                {selectedRooms.length > 0 &&
                                                <>
                                                  
                                                    {selectedRooms.map((item, index) =>{

                                                        const valueAdds = item?.["value-adds"]?.["value-add"]; // Safe access
                                                        const totalPricewithMarkup = item?.updatedPriceWithMarkup; // Safe access
                                                        
                                                        const localCollectedFee = item?.localCollectedFee?.fees; // Safe access


                                                        // Função para verificar se um código específico está presente
                                                        const hasCode = (code) => {
                                                            if (!valueAdds) return false;

                                                            // Verifica se é um array
                                                            if (Array.isArray(valueAdds)) {
                                                            return valueAdds.some((valueAdd) => valueAdd.code === code);
                                                            }

                                                            // Caso seja um objeto único
                                                            return valueAdds.code === code;
                                                        };

                                                        const hasFreeCancellation = hasCode("free_cancellation");
                                                        const hasFreeBreakfast = hasCode("free_breakfast");                                                        

                                                        return (
                                                        <>
                                                            {/* ROOM 1 */}
                                                            <div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
                                                            
                                                                <div className="filterOptions_priceFilter__options" style={{color: "#475467", fontFamily: "Poppins", fontSize: "1.125rem", fontStyle: "normal", fontWeight: 500, lineHeight: "1.625rem",letterSpacing: "0.02875rem",width:"100%",textAlign:"left"}}>Quarto {index+1}</div>

                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                        <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{item.room.title}</div>
                                                                        <div style={{flex:1,textAlign:"right"}}><Button text className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem",height:"20px"}} loading={removingRoomLoading[item.uniqueId] || false} onClick={()=>removeRoom(item.uniqueId)} label="Remover" /></div>
                                                                </div>      

                                                                <div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                                    <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                    <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Política de cancelamento</div>
                                                                                    </div>
                                                                    </div>
                                                                    <div className="w100 ">
                                                                        <div className="flex-column justify-center align-items-start w100" style={{gap:"0.5rem"}}>

                                                                        {hasFreeCancellation && <span className="label" style={{color:"green",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> Cancelamento Gratuito <label style={{color:"#878787"}}>até {moment(item['cancellation-deadline']).businessSubtract(2, 'days').format('DD/MM/YYYY')}</label></span>}
                                                                        </div>                                                                          
                                                                    </div>   

                                                                    </div>

                                                                    <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                        
                                                                    <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                                    </div>
                                                                    </div>
                                                                    <div className="w100 ">

                                                                        <div className="flex-column justify-center align-items-start w100" style={{gap:"1rem"}}>

                                                                            {hasFreeBreakfast && <span className="label" style={{color:"green",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> Café da manhã incluído</span>}

                                                                            {!hasFreeBreakfast && <span className="label" style={{color:"gray",fontSize:"0.8rem",textAlign:"left"}}><i style={{color:"#00A3FF"}} className="pi pi-times"></i> Nenhuma opção extra</span>}                                                                                
                                                                        </div>
                                                                        
                                                                    </div>                                                        

                                                                    </div>   

                                                                    <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                        <div className="w100 ">
                                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                            <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                                            <div style={{flex:1,color: "#475467",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{item.roomHostsLabel}</div>
                                                                            </div>
                                                                        </div>                                                           
                                                                    </div>   


                                                                    <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                        <div className="w100 ">
                                                                            <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                            <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Subtotal quarto {index +1}</div>
                                                                            <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{formatNumberCurrency(totalPricewithMarkup,exchangeRateInitials)}</div>
                                                                            </div>
                                                                        </div>                                                           
                                                                    </div>  

                                                                    {localCollectedFee.length > 0 && (
                                                                        <div className="filterOptions_priceFilter__options" style={{color: "#878787", fontFamily: "Poppins", fontSize: "1rem", fontStyle: "normal", fontWeight: 400, lineHeight: "1.625rem",letterSpacing: "0.02875rem",width:"100%",textAlign:"left"}}>Tarifas cobradas no local</div>
                                                                    )}
                                                                    {localCollectedFee.map((fee, index) => {
                                                                        return (<><div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                            <div className="w100 ">
                                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.75rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{fee.name}</div>
                                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.75rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{formatNumberCurrency(fee.total,fee.currency)}</div>
                                                                                </div>
                                                                            </div>                                                           
                                                                        </div>  
                                                                        </>);
                                                                    })}

                                                                </div>

                                                            </div>                                                        
                                                        </>
                                                        );

                                                    })}


                                                    {/* TOTALS */}
                                                    <div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
    

                                                        <div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                            <div className="flex-column justify-center align-items-right w100">

                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #00A3FF",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.12938rem",textAlign:"right"}}><span style={{fontSize: "0.9375rem"}}>Total </span> {formatNumberCurrency(totalAmount,exchangeRateInitials)}</div>		
                                                                
                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem",textAlign:"right"}}>inclui impostos e taxas</div>

                                                                {totalCollectedFeeAmount &&
                                                                    <>
                                                                    <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "2.12938rem",textAlign:"right",marginTop:"1rem"}}><span style={{fontSize: "0.85rem"}}>Total de tarifas cobradas no local </span> {formatNumberCurrency(totalCollectedFeeAmount,exchangeRateInitials)}</div>       
                                                                    </>
                                                                }                                                         

                                                                <div style={{marginTop:"1rem"}}>
                                                                    <Button label="Iniciar reserva" rounded className="pT10 mR10 headerButton tColorWhite w100" onClick={()=>{console.log("RESERVAR")}} />
                                                                </div>	                                                            
                                                                
                                                            </div>   

                                                        </div>
                                                    
                                                    </div>

                                                    {/* ROOM 2 */}
                                                    {/*
                                                    <div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
                                                    
                                                        <div className="filterOptions_priceFilter__options" style={{color: "#475467", fontFamily: "Poppins", fontSize: "1.125rem", fontStyle: "normal", fontWeight: 500, lineHeight: "1.625rem",letterSpacing: "0.02875rem",width:"100%",textAlign:"left"}}>Quarto 2</div>

                                                        <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Double Platinum Master Class 4</div>
                                                                <div style={{flex:1,textAlign:"right"}}><span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>console.log("remover")}>Remover</span></div>
                                                        </div>      

                                                        <div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div className="w100 ">
                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Cancelamento</div>
                                                                    </div>
                                                                </div>
                                                                <div className="w100 ">
                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Após 10/10/2023</div>
                                                                    <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+100,00 &euro;</div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div className="w100 ">
                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                    </div>
                                                                </div>
                                                                <div className="w100 ">
                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Café da manhã</div>
                                                                    <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+35,00 &euro; / pessoa</div>
                                                                    </div>
                                                                </div>                                                           
                                                            </div>   

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                                <div className="w100 ">
                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                                    <div style={{flex:1,color: "#475467",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>2 adultos, 1 criança</div>
                                                                    </div>
                                                                </div>                                                           
                                                            </div>   

                                                        </div>
                                                    
                                                    </div>*/}

                                                </>
                                                }                                                


											</div>
                                            </>
                                            }

										</div>

									</div>
			                                    

						</div>


					</div>				
					
					</div>

				</div> 
			</div>

    </div>	

		<ImageGalleryZoomModal
			propertyId={selectedItem}
			setSelectedItem={setSelectedItem}
			showPropertyInfo={true}
            images={images}
			scrollBlock={true}
			exchangeRateInitials={exchangeRateInitials}
			usdExchangeRate={usdExchangeRate}
			dateRangeDays={dateRangeDays}
			hostsAndRoomsLabel={hostsAndRoomsLabel}	
			handleCardClick={handleCardClick}				
		/>

		<ImageGalleryRoomModal
			roomInfo={roomInfo}
			images={roomImages}
            roomData={roomData}
			scrollBlock={true}			
		/>

		<RoomAmenitiesModal
			roomData={roomData}
			scrollBlock={true}			
		/>

		<PropertyPoiModal
			pois={resultsPois}
			type="address" // or coordinates
			address={addressLocationText} // set if type is address		
			coordinates={null} // set if type is coordinates	            
			scrollBlock={true}			
		/>

		<MapViewModal
			type="address" // or coordinates
			address={addressLocationText} // set if type is address		
			coordinates={null} // set if type is coordinates		
			scrollBlock={true}				
		/>

		<AlertView
			alert={alert}
			setAlert={setAlert}
			alertTitle={alertTitle}
			alertBody={alertBody}	
			scrollBlock={false}				
		/>

		<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	

        {/* Instanciando o componente e passando os estados e funções como props */}
        {/*<Timer
            searchResultToken={searchResultToken}
            checkSearchResult={checkSearchResult}
            setAlertExpire={setAlertExpire}
            setAlertTitleExpire={setAlertTitleExpire}
            setAlertBodyExpire={setAlertBodyExpire}
            setAlertActionExpire={setAlertActionExpire}
        />*/}            

		{/* Contac Us Section */}
		<ContactUs />

		{/* About Us Section */}
		<AboutUs />
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser,
  currentAgency:store.appReducer.currentAgency,

  searchType:store.orderReducer.searchType,
  searchParams:store.orderReducer.searchParams,
  searchResultStatus:store.orderReducer.searchResultStatus,
  searchResultRowsNumber:store.orderReducer.searchResultRowsNumber,
  searchResultToken:store.orderReducer.searchResultToken,
  searchResultCurrentPage:store.orderReducer.searchResultCurrentPage,
  searchResultLimit:store.orderReducer.searchResultLimit,
  searchLocation:store.orderReducer.searchLocation,
  searchResultObj:store.orderReducer.searchResultObj,
  searchCurrency:store.orderReducer.searchCurrency,
  exchangeRates:store.orderReducer.exchangeRates
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HotelDetails);	


