import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {useLocation,useNavigate} from 'react-router-dom';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as OrderActions from '../../Store/Actions/Order'

import AlertView from '../../Components/AlertModal';

import imagePlaceHolder from '../../Assets/Images/noimg.jpg';

import {
	formatNumberCurrency,
    padronizarDecimal
} from '../../Components/Utils';

// UpdateService dialog component
const ImageGalleryView = (props) => {

// set vars from props
const { 
    dialog,
    setDialog,
    setSelectedItem,
    showPropertyInfo,
	propertyId,
	scrollBlock,
    retrieveSearchResultProperty,
    searchResultToken,
    exchangeRateInitials,
    usdExchangeRate,
    dateRangeDays,
    hostsAndRoomsLabel,
    handleCardClick
} = props;

const [loading, setLoading] = React.useState(true); 
const [images, setImages] = React.useState(null);
const [activeIndex, setActiveIndex] = React.useState(0);    
const [resultCard, setResultCard] = React.useState({});    

const [imageLoading, setImageLoading] = React.useState(true);    

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [contentLoading, setContentLoading] = React.useState(true); 
const [notFound, setNotFound] = React.useState(false); // Not found control

const galleria = React.useRef(null);


// Search properties method
const executeRetrieveSearchPropertyResult = () => {

	// Keep default values or override 
	setResultCard({});
	
	// Set loading ON and timer ON
	setContentLoading(true);

	// Prepare props and data
	var props = {
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setContentLoading:setContentLoading,
		setResultCard:setResultCard,
		setNotFound:setNotFound
	}
	var data = {
		searchResultToken:searchResultToken,
		propertyId:propertyId
	}		

	// if searchType is location
	retrieveSearchResultProperty(data,props);
 
}

const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);		
	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'ImageGalleryViewModal' && dialog.status) {

        setActiveIndex(0);
        setImageLoading(true);
        executeRetrieveSearchPropertyResult();       
		
	} // end if
	
	// Reset all fields when closes or open dialog
	
			
},[dialog]);


const close = ()=> {

	setDialog("ImageGalleryViewModal",false);

    setSelectedItem(null);

	setTimeout(function() {
		if (scrollBlock) {
			// document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
    
}

const itemTemplate = (item) => {
    return (
            <div style={{ position: 'relative', width: '100%', minHeight: '60vh' }}>
                {imageLoading && (
                    <div 
                        style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                            color: '#fff', 
                            zIndex: 2 
                        }}
                    >
                        Carregando...
                    </div>
                )}
                <img 
                    src={item.photo_url.replace("max500", "max800")} 
                    style={{ width: '100%', height: 'auto', borderRadius: '1rem', padding: '0.5rem' }} 
                    onLoad={() => setImageLoading(false)} // Remove o loading quando a imagem carregar
                    onError={handleImageErrorGallery} // Caso ocorra um erro, remove o loading também
                />
            </div> 
    );   

    //return <img src={item.photo_url.replace("max500", "max800")}  style={{ width: '100%',height:"auto",borderRadius:"1rem",padding:"0.5rem"}} />;
};

const handleImageError = (event) => {
    setImageLoading(false);
   // event.target.src = imagePlaceHolder; // Define o placeholder importado como fallback
    event.target.style.display = "none"; // Esconde a imagem ao ocorrer um erro

    if (event.target.parentElement) {
        event.target.parentElement.style.display = "none"; // Oculta o div pai
    }    

};
const handleImageErrorGallery = (event) => {
    setImageLoading(false);
   // event.target.src = imagePlaceHolder; // Define o placeholder importado como fallback
    event.target.style.display = "none"; // Esconde a imagem ao ocorrer um erro
};

	return (
	<>
			<Dialog className="galleryDialog" closable={true} closeOnEscape={true} dismissableMask={true} header={showPropertyInfo ? "Informações e fotos da propriedade" : "Fotos da propriedade"} visible={dialog.target == 'ImageGalleryViewModal' && dialog.status} style={{ width: '90vw',height:'90vh' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >


            	{/* LOADING  */}
				{(contentLoading) &&
					<>
						<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row"}}>
						
								<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
								<div style={{fontSize:"16px",marginTop:"0px"}}> Buscando dados da propriedade... </div>
																				
						</div>
						
                        <div className="w100 flex-row justify-center align-items-stretch" style={{gap:"40px",height:"50vh"}}>
                            <div style={{flex:0.7}}>
                                <Skeleton width="100%" height="4rem" ></Skeleton>
                                <br />
                                <Skeleton width="100%" height="4rem" ></Skeleton>
                                <br />
                                <Skeleton width="100%" height="4rem" ></Skeleton>
                                <br />
                                <Skeleton width="100%" height="4rem" ></Skeleton>
                                <br />
                                <Skeleton width="100%" height="4rem" ></Skeleton>                                
                            </div>
                            <div style={{flex:1}}>
                                <Skeleton width="100%" height="100%" ></Skeleton>
                            </div>
                        </div>
						
					</>				
				}

                {(!contentLoading) &&
                <>
				<div className="w100 flex-row justify-center align-items-stretch" style={{height:"100%",gap:"1rem"}}>
					
                    <div className="imageGallery_wrapper__left flex-column justify-stretch align-items-center" style={{flex:0.7,overflow:"hidden"}}>


                        <div className="imageGallery_details w100" style={{flex:showPropertyInfo ? 1 : "unset"}}>

                            <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"0.3rem",flex:1,padding:"0.5rem"}}>

                                <div className="resultsCard_details__name w100" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left",display:"flex",flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>
                                    
                                        <div className="w100 resultsCard_details__left flex-row justify-start align-items-center" >
                                            <div className="resultsCard_details__name" style={{flex:1,color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                            fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{resultCard.hotel_name}</div>
                                            <div className="resultsCard_details__ratingValue" style={{display: "flex",width: "3.875rem",height: "auto",padding: "0.625rem",flexDirection: "column",justifyContent: "center",alignItems: "center",gap: "0.625rem",borderRadius: "1.8125rem",background: "#00A3FF",color: "#FFF",fontFamily: "Titillium Web",fontSize: "1rem",fontStyle: "normal",fontWeight: 600,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{padronizarDecimal(resultCard.review_rating)}</div>
                                        </div>

                                        <div className="resultsCard_details__location" style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>{resultCard.country}, {resultCard.city} {resultCard.neighborhood ? ", "+resultCard.neighborhood : ""}</div>

                                        {resultCard.distanceToCityCenter && (<div className="resultsCard_details__distance" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>{resultCard.distanceToCityCenter ? (resultCard.distanceToCityCenter < 700 ? (resultCard.distanceToCityCenter+" m do centro") : (resultCard.distanceToCityCenter / 1000).toFixed(2)+" km do centro") : ""}</div>)}

                                        <div className="resultsCard_details__amenities">
                                        <ul style={{listStyleType: "none",textAlign:"left", columns: (resultCard.amenitiesGroups.length > 4) ? ((resultCard.amenitiesGroups.length > 10) ? 3 : 2) : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>
                                            {resultCard.amenitiesGroups.map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.label}</li>))}
                                            </ul>
                                        </div>

                                </div>

                                {showPropertyInfo &&
                                <>
                                   
                                    <div className="resultsCard_details__prices flex-column justify-start align-items-center w100" style={{gap:"0.5rem",textAlign:"left"}}>

                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}> {/*Diária: {formatNumberCurrency(item.total_with_markup / dateRangeDays,item.display_price.currency)}  <br />*/}   {dateRangeDays > 1 ? `${dateRangeDays} diárias` : `${dateRangeDays} diária`} {/*, {rooms.length > 1 ? `${rooms.length} quartos` : `${rooms.length} quarto`} */} <br /> {hostsAndRoomsLabel}</div>
                                                                    
                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #814DE5",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.1rem"}}><span style={{fontSize: "0.9rem"}}>À partir de </span> {formatNumberCurrency(exchangeRateInitials == "BRL" ? resultCard.total_with_markup * usdExchangeRate : resultCard.total_with_markup,exchangeRateInitials)}</div>	

                                    </div>

                                    <div className="w100 flex-row justify-start align-items-center" style={{marginTop:"1rem",marginBottom:"1rem"}}>
                                            <Button label="Ver hotel em detalhes" rounded className="pT10 tColorWhite" onClick={()=>{setDialog("ImageGalleryViewModal",false);handleCardClick(resultCard.hotelid_gar)}} />
                                    </div>	                                
                                </>
                                }


                            </div>

                        </div>

                        <div className="imageGallery_thumbs w100" style={{flex:0.7,overflowY:"scroll"}}>
                        
                            <div className="imageGallery grid" style={{maxHeight:"100%",width:"100%",display:"flex",justifyContent:"start",alignItems:"start",flexDirection:"row",flexWrap:"wrap",gap:"0.75rem",padding:"0.5rem"}}>
                                {
                                    resultCard.photos && resultCard.photos.map((image, index) => {
                                        let imgEl = <img src={image.photo_url}  style={{ cursor: 'pointer',width:"100%",height:"100%", objectFit:"cover",objectPosition:"center" }} onClick={
                                            () => {setActiveIndex(index);setImageLoading(true);galleria.current.show()}
                                        } onError={handleImageError} />
                                        return (
                                            <div className={activeIndex == index ? "imageGallery_item selected" : "imageGallery_item"} key={index} style={{borderRadius:"1rem",width: "6.5rem",height:"5rem",overflow:"hidden"}}>
                                                {imgEl}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                    </div>

                    <div className="imageGallery_wrapper__right flex-column justify_center align-items-stretch w100" style={{flex:1}}>
                    
                        <div style={{width:"100%",height:"100%",flex:1}}>
                            <Galleria  ref={galleria}   activeIndex={activeIndex} onItemChange={(e) => {setActiveIndex(e.index);setImageLoading(true)}}  circular value={resultCard.photos} style={{display:"flex",justifyContent:"start",alignItems:"center",borderRadius:"1rem"}} showThumbnails={false}  showItemNavigators showIndicators showIndicatorsOnItem={true} indicatorsPosition="bottom" item={itemTemplate} />
                        </div>

                    </div>

			    </div>
                </>
                }

                <AlertView
                    alert={alert}
                    setAlert={setAlert}
                    alertTitle={alertTitle}
                    alertBody={alertBody}	
                    scrollBlock={false}				
		        />                

            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  searchResultToken:store.orderReducer.searchResultToken,
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ImageGalleryView);	
