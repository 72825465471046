
export const BUSINESS_RULES = {
    timeoutMinutes: 30,
    holidays: [
        // Feriados Nacionais
        '2025-01-01', // Ano Novo
        '2025-04-21', // Tiradentes
        '2025-05-01', // Dia do Trabalho
        '2025-09-07', // Independência do Brasil
        '2025-10-12', // Nossa Senhora Aparecida
        '2025-11-02', // Finados
        '2025-11-15', // Proclamação da República
        '2025-12-25', // Natal

        // Feriados Estaduais do Rio de Janeiro
        '2025-04-23', // Dia de São Jorge
        '2025-11-20', // Dia da Consciência Negra

        // Feriados Municipais do Rio de Janeiro
        '2025-01-20', // Dia de São Sebastião (Padroeiro do Rio de Janeiro)

        // Pontos facultativos amplamente tratados como feriados
        '2025-03-03', // Carnaval (segunda-feira)
        '2025-03-04', // Carnaval (terça-feira)
        '2025-06-19', // Corpus Christi (quinta-feira)
    ]
};

export const UI_SETTINGS = {
    theme: 'dark',
    language: 'pt-BR',
    dateFormat: 'DD/MM/YYYY'
};

