import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import {APIProvider, Map,Marker} from '@vis.gl/react-google-maps';
import { ProgressSpinner } from 'primereact/progressspinner';

// Load Actions
import * as AppActions from '../../Store/Actions/App';


const apiKey = 'AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs';


// UpdateService dialog component
const RoomAmenitiesView = (props) => {

// set vars from props
const { 
    dialog,
    setDialog,
	roomData,
	scrollBlock
} = props;


const [loading, setLoading] = React.useState(false); 


const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);		
	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'RoomAmenitiesViewModal' && dialog.status) {

  
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

// Execute when dialog opens
React.useEffect(() => {
	
	// Reset all fields when closes or open dialog
  // Runs when UNMOUNT component
  return () => {
    
  };
			
},[]);

const close = ()=> {

	setDialog("RoomAmenitiesViewModal",false);
    
	setTimeout(function() {
		if (scrollBlock) {
			// document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300); 

}
	
	return (
	<>
			<Dialog header="Comodidades do quarto" closable={true} closeOnEscape={true} dismissableMask={true} visible={dialog.target == 'RoomAmenitiesViewModal' && dialog.status} style={{ width: '55vw',height:'70vh' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
				<div className="w100" style={{padding:"1rem",height:"100%"}}>
                        
                    {loading &&
			                <div style={{backgroundColor:"transparent",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"45vh",padding:"30px"}}>

                                <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"20vw",textAlign:"center"}}>
                                    <ProgressSpinner />
                                    <div className="formTitle" style={{marginBottom:"15px",marginTop:"15px",fontSize:"2rem"}}>Carregando...Aguarde! </div>
                                </div>

                            </div>           
                    }

                    {!loading &&
                    <>
                    <div className="w100 flex-column justify-center align-items-start">
                       
                        <div style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                        fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>Título do quarto: {roomData?.room?.title}</div>

                        <div className="resultsCard_details__amenities w100">
                            <ul style={{listStyleType: "none",textAlign:"left",columns:"200px",columnGap:"38px",columnFill:"balance", padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>

                            {roomData?.extraDetails?.amenities.map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.name.en}</li>))}

                            </ul>
                        </div>
                    </div>
                    </>
                    }

			    </div>
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(RoomAmenitiesView);	
