// APP REDUCER 
export const INNER_LOADING = "app/inner/loading";
export const SET_DIALOG = "app/set/dialog";
export const SET_LOGINSTATUS = "app/set/loginStatus";
export const SET_ROUTEPANEL = "app/set/route/panel";
export const SET_ROUTEMANAGE = "app/set/route/manage";
export const SET_CURRENTAGENCY = "app/set/current/agency";
export const SET_MASTERUSER = "app/set/master/user";

export const SET_AGENCYNAME = "app/set/agency/name";
export const SET_AGENCYUSER = "app/set/agency/user";
export const SET_CURRENTCOMPANYSTATUS = "app/set/current/agency/status";
export const SET_ACTIVATIONSTATUS = "app/set/agency/activation/status";


// ORDER REDUCER
export const SET_SEARCHTYPE = "order/set/searchType";
export const SET_SEARCHPARAMS = "order/set/searchParams";
export const SET_SEARCHFILTERS = "order/set/searchFilters";
export const SET_APPLIEDSEARCHFILTERS = "order/set/applied/searchFilters";
export const SET_SEARCHCURRENCY = "order/set/searchCurrency";
export const SET_SEARCHRESSTATUS = "order/set/searchStatus";
export const SET_SEARCHRESROWSNUMBER = "order/set/searchRowsNumber";
export const SET_SEARCHRESTOKEN = "order/set/searchToken";
export const SET_SEARCHRESCURRENTPAGE = "order/set/searchCurrentPage";
export const SET_SEARCHRESLIMIT = "order/set/searchResultLimit";
export const SET_SEARCHLOCATION = "order/set/searchLocation";
export const SET_SEARCHRESOBJECT = "order/set/searchResultObject";
export const SET_EXCHANGERATES = "order/set/exchangeRates";

// RESERVATION REDUCER
export const SET_RESERVATION = "reservation/set/selected";
export const SET_RESERVATIONSROWS = "reservation/set/rows";

// AGENCY REDUCER
export const SET_AGENCY = "agency/set/selected";
export const SET_AGENCIESROWS = "agency/set/rows";

// USER REDUCER
export const SET_USER = "user/set/selected";
export const SET_USERSROWS = "user/set/rows";

// CUSTOMER REDUCER
export const SET_CUSTOMER = "customer/set/selected";
export const SET_CUSTOMERSROWS = "customer/set/rows";

// ROUTE REDUCER
export const SET_ROUTE = "route/set/selected";
export const SET_ROUTESROWS = "route/set/rows";


