import '../Assets/Styles/general.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate,useLocation,Route,Routes,Navigate} from 'react-router-dom';
import io from 'socket.io-client';

// Components Loading
import { Button } from 'primereact/button'; 
import { ScrollTop } from 'primereact/scrolltop';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ProgressSpinner } from 'primereact/progressspinner';

import jwtDecode from 'jwt-decode';

// External Libs loading
import routes from "../routes.js";
import * as config from '../Config/api.js';

// Import template images
import logo from '../Assets/Images/logoHeader.svg'; 
import iconProfile from '../Assets/Images/iconProfile.svg'; 
import iconRoute from '../Assets/Images/iconRoute.svg';
import formTopIcons from '../Assets/Images/formTopIcons.svg';  

// Load Actions
import * as AppActions from '../Store/Actions/App'

import AuthLogin from '../Components/Auth/AuthLogin';

// Load Components
import RegisterView from '../Screens/Home/RegisterModal';
import SelectAgencyView from '../Components/SelectAgencyModal';
import AlertView from '../Components/AlertModal';
import ValidateView from '../Components/ValidateModal';
import moment from 'moment';
// import RouteManage from '../Screens/Home/RouteManage';

const Desktop = (props) => {

	// Set var from props
	const {
		setDialog,
		dialog,
		loginStatus,
		setLoginStatus,
		routePanel,
		setRoutePanel,
		setRouteManage,
		routeManage,
		currentAgency,
		setCurrentAgency,
		masterUser,
		agencyName,
		agencyUser,
		setMasterUser,
		setAgencyName,	
		setAgencyUser,
		setCurrentCompanyStatus,
		currentCompanyStatus,
		setActivationStatus,
		activationStatus,
		retrieveAndSetUserInfo		
	} = props;
	
	// login status
	const [menuOpened,setMenuOpened] = React.useState(false);
	const [promptStatus,setPromptStatus] = React.useState(false);
	
// Alert control
const [validateRegistry, setValidateRegistry] = React.useState(false); 
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 	

// Global loading for User Data Loading If logged and not approved (only for under review or repproved status)
var logged = localStorage.getItem("@memovo/logged");
const [globalLoading, setGlobalLoading] = React.useState(logged == "1" ? true : false); 	

// Set location
const location = useLocation();
const { hash, pathname, search } = location;
	
// set Use of history for PUSH 
var navigate = useNavigate();	
const op = React.useRef(null);

// Store socket first state
const socketRef = React.useRef(null);	

	const getRoutes = (routes) => {
	return routes.map((prop, key) => {
		return (
			<Route
			exact path={prop.path}
			element={<prop.component />}
			logOut={logOut}
			key={key}
			/>
		);
	});
	};  
  
	const onHide = () => {
	setMenuOpened(false);
	}  

	const onShow = () => {
	setMenuOpened(true);
	}    
		
	const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  
	
	 // Logoff method
	const logOut = () => {

		// Set logoff flag
		localStorage.setItem("@memovo/logged",0);
		
		// Remove userData 
		localStorage.removeItem("@memovo/userData");	
		localStorage.removeItem("@memovo/companyStatus");	
		localStorage.removeItem("@memovo/activationStatus");
		
		// Remove Session ALSO
		sessionStorage.removeItem("@motuum/searchingProvider");	
		sessionStorage.removeItem("@motuum/serviceOfferId");	
		sessionStorage.removeItem("@motuum/quotationId");	
		sessionStorage.removeItem("@motuum/sessionId");
		sessionStorage.removeItem("@motuum/selectedProvider");

		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
		
		setLoginStatus(false);
		navigate("/home");
		
	}	

	// Actions to process accoring to websocket return
	const processWebsocketAction = (value) => {

		var action = value.action;

		switch (action) {

			case 'updateCompanyStatus':
					
				// Dont change master user activation status
				if (!masterUser) {
					setCurrentCompanyStatus(parseInt(value.newStatus));
					localStorage.setItem("@memovo/companyStatus",value.statusToken);	
				}
				
				break;
			case 'updateActivationStatus':
				
				// Dont change master user activation status
				if (!masterUser) {
					setActivationStatus(value.newStatus === "1" ? true : false);
				} // end if

			break;

			case 'forceLogoff':

				// only force logoff of non master user - to avoid drop main master logged user
				if (!masterUser) {
					setAlert(true);
					setAlertTitle("Você foi desconectado!"); 
					setAlertBody("Você foi desconectado pela administrador do sistema. Faça novamente o seu login."); 				

					logOut();
				} // erd if

			break;

			case 'showMessage':

				// Dont show all message for master user
				if (!masterUser) {
					setAlert(true);
					setAlertTitle(value.title ? value.title : "Você recebeu uma mensagem"); 
					setAlertBody(value.message); 
				} // end if		

			break;

			default:
			  console.log("Nothing to do...");
		  }


	}

  // Execute when LocalStorage Changes TO AVOID HACKING ON BROWSER INSPECTOR
  const handleStorageChange = (event) => {

		console.log('Storage change detected! [2]');
		//console.log('event.key >>>> '+JSON.stringify(event.key,null,2));
		//console.log('event.oldValue >>>> '+JSON.stringify(event.oldValue,null,2));
		//console.log('event.newValue >>>> '+JSON.stringify(event.newValue,null,2));
//		console.log(event.key, event.oldValue, event.newValue, event.url, event.storageArea);

		if (event.key) {
			if (event.oldValue === null) {

			  console.log(`Item adicionado - chave: ${event.key}, valor: ${event.newValue}`);

			} else if (event.newValue === null) {

			  console.log(`Item removido - chave: ${event.key}, valor antigo: ${event.oldValue}`);
 
				// Check if userData Exist. If not, Logoff and redirect to Home
				var result = localStorage.getItem("@memovo/userData");
				var userData = JSON.parse(result); 
				var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
				var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  
				var logged = localStorage.getItem("@memovo/logged");

				console.log('resultCompanyStatus >>>> '+JSON.stringify(resultCompanyStatus,null,2));
				console.log('resultActivationStatus >>>> '+JSON.stringify(resultActivationStatus,null,2));
				console.log('logged >>>> '+JSON.stringify(logged,null,2));

				// If there is no logged localStorage, logout
				if (!logged || logged == "0") {
					logOut();
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					return;
				} // end if 

				// If any required localStorage Value is present, logout
				if (!userData || !resultCompanyStatus || !resultActivationStatus) {
					logOut();
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (2) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					return;
				} // end if  

				try {
					var userDataTokenObjDecoded = jwtDecode(userData.userToken);
					if (!userDataTokenObjDecoded.CompanyPersonUserId) {
						logOut();
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						return;
					} // end if 
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3.1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch				

			} else {
			  console.log(`Item atualizado - chave: ${event.key}, valor antigo: ${event.oldValue}, valor novo: ${event.newValue}`);

				try {
					var userDataTokenObjDecoded = jwtDecode(userData.userToken);
					if (!userDataTokenObjDecoded.CompanyPersonUserId) {
						logOut();
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						return;
					} // end if 
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (6) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch

				// Set CurrentCompanyStatus
				let companyStatusFlag = false;
				if (resultCompanyStatus) { companyStatusFlag = true; }
				try {
					var companyStatusObjDecoded = jwtDecode(resultCompanyStatus); 
					var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
					props.setCurrentCompanyStatus(currentCompanyStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (7) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch
				
				// Set ActivationStatus
				let activationStatusFlag = false;
				if (resultActivationStatus) { activationStatusFlag = true; }	
				try {
					var activationStatusObjDecoded = jwtDecode(resultCompanyStatus);
					var activationStatus = activationStatusObjDecoded.currentStatus;
					props.setActivationStatus(activationStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (8) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch		

			}
		  } else {
			console.log('Todo o storage pode ter sido limpo, ou uma chave nula foi manipulada');
		  }		

  };	
	
// Execute when route changes
React.useEffect(() => {

    // Função que você deseja executar a cada mudança de rota
   // console.log('A rota mudou!', location.pathname);
    
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result);   

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  // console.log('SET LOGIN TRUE');
		  setLoginStatus(true);
		  return;
	  } // end if
	  else {
		// console.log('SET LOGIN FALSE');
	  }
    
  }, [location]); // Dependência do useEffect é a localização atual

// Run after mount component and Navigate changes
React.useEffect(() => {

	// Check if userData Exist. If not, Logoff and redirect to Home
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result); 
	var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
	var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  

	// If any required localStorage Value is present, logout
	if (!userData || !resultCompanyStatus || !resultActivationStatus) {
		logOut();
		return;
	} // end if  

	try {
		var userDataTokenObjDecoded = jwtDecode(userData.userToken);
		if (!userDataTokenObjDecoded.CompanyPersonUserId) {
			logOut();
			console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (11) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
			return;
		} // end if 
	} catch(e) {
		console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (11.1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
		logOut();
	} // end catch	


	// redirect according to status
	/*if (loginStatus && currentCompanyStatus === 1 && activationStatus === true) {
		navigate("/order");
		return;
	} // end if*/
	if (loginStatus && currentCompanyStatus === 1 && activationStatus === false) {
		navigate("/home");
		return;
	} // end if		
	else if (loginStatus && (currentCompanyStatus === 0 || currentCompanyStatus === 2 || currentCompanyStatus === 3)) {
		navigate("/home");

		// if review and activation is false, open activation modal. [CHECK IF KEEP]
		if(currentCompanyStatus === 0 && !activationStatus) {
			// setDialog('validateEmail',true);
		}

		return;
	} // end if	

  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, [navigate]); // end useEffect 

// Run one time after mount component
React.useEffect(() => {
  
   setRoutePanel(false);

    // Adiciona o listener do STORAGE ao montar o componente
    window.addEventListener('storage', handleStorageChange);   

   // Check if userData Exist. If not, Logoff and redirect to Home
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result); 
	var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
	var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  

	// If any required localStorage Value is present, logout
	if (!userData || !resultCompanyStatus || !resultActivationStatus) {
		setGlobalLoading(false);
		logOut();
		return;
	} // end if  
	 
	// check userToken
	try {
		var userDataTokenObjDecoded = jwtDecode(userData.userToken);
		if (!userDataTokenObjDecoded.CompanyPersonUserId) {
			setGlobalLoading(false);
			logOut();
			return;
		} // end if 
	} catch(e) {
		logOut();
		return;
	} // end catch	

	// Set Global loading on since its logged
	setGlobalLoading(true);
		
	// Set logged 
	localStorage.setItem("@memovo/logged", "1");
	setLoginStatus(true);

	var props = {
		setGlobalLoading:setGlobalLoading,
		logOut:logOut,
		setLoginStatus:setLoginStatus,
		setMasterUser:setMasterUser,
		setAgencyName:setAgencyName,
		setAgencyUser:setAgencyUser,
		setCurrentCompanyStatus:setCurrentCompanyStatus,
		setActivationStatus:setActivationStatus,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody
	}
	var data = {
	  loginStatus: loginStatus
	}
	retrieveAndSetUserInfo(data,props);	

	// Runs when UNMOUNT component
	return () => {
		
		setRoutePanel(false);
		
		// Remove listener
		window.removeEventListener('storage', handleStorageChange);

		// Disconnect WEBSOCKET
		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
		
	};

}, []); // end useEffect 



// Check currentCompanyStatus changes and redirect if needed
React.useEffect(() => {

	  // redirect according to status
	  if (loginStatus && currentCompanyStatus === 1 && activationStatus) {
		  navigate("/order");
		  return;
	  } // end if	
	  // redirect according to status
	  else if (loginStatus && currentCompanyStatus === 1 && !activationStatus) {
		navigate("/home");
		return;
	  } // end if		  
	  else if (loginStatus && (currentCompanyStatus === 0 || currentCompanyStatus === 2 || currentCompanyStatus === 3)) {
		navigate("/home");

		// if review and activation is false, open activation modal. [CHECK IF KEEP]
		if(currentCompanyStatus === 0 && !activationStatus) {
			// setDialog('validateEmail',true);
		}

		return;
	} // end if		  
  
}, [currentCompanyStatus,activationStatus]); // end useEffect

// Execute when loginStatus changes
React.useEffect(() => {
	
	  // if logged connect web socket. 
	  if (loginStatus) {

			// Set WEBSOCKET connection
			var result = localStorage.getItem("@memovo/userData");
			var userData = JSON.parse(result);     	

			socketRef.current = io(config.WS_URL, {
				auth: {
				// Substitua 'seu_jwt_token_aqui' pela lógica para obter o JWT
				token: userData ? userData.userToken : null
				},
				transports: ['websocket'],  // força o uso de WebSocket
			}); 
			

			socketRef.current.on('mensagemDoServidor', (data) => {

				// Process action according to received data
				processWebsocketAction(data);

			});	

			// Connect WEBSOCKET only when mount
			socketRef.current.on('connect', () => {
				console.log('Conectado ao servidor WebSocket');
				socketRef.current.emit('autenticar', { token: userData.userToken }); 
			});

			socketRef.current.on('connect_error', (error) => {
				console.log('Erro de conexão:', error);
			  });
			  
			  socketRef.current.on('connect_timeout', (timeout) => {
				console.log('Timeout de conexão:', timeout);
			  });
			  
			  socketRef.current.on('error', (error) => {
				console.log('Erro geral:', error);
			  });

	  } // end if	
	  else  {
		// Disconnect WEBSOCKET
		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
	} // end if		  
  
}, [loginStatus]); // end useEffect 

	/* Logout prompt START  */
    const accept = (e) => {
	   logOut();
	  // op.current.toggle(e);
    };

    const reject = (e) => {
       op.current.toggle(e);
    };
	
    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: <div>Tem certeza que deseja <br /> fazer o logoff e sair?</div>,
            icon: 'pi pi-exclamation-triangle',
			accept,
            reject,
			rejectLabel:"Não",
			acceptLabel:"Sim"
        });
    };
	/* Logout prompt END  */	
	

/* Set current agency method */	
const setCurrentAgencyAction = () => {
	
	setDialog('selectAgencyModal',true);
	
} // end method

/* Set current agency method */	
const resetCurrentAgencyAction = () => {
	
	setCurrentAgency({});
	
	// Redirect to Order Start
	navigate("/order");
	
} // end method

// If globalLoading True, show loading screen
if (globalLoading) {
return(
<div className="wrapper" style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

	<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
		<ProgressSpinner />
		<div className="formTitle" style={{marginBottom:"40px"}}>Carregando...</div>

		<div style={{marginBottom:"20px",display:"flex",flexdirection:"row",alignItems:"center",justifyContent:"center"}}>

			<svg className="globalLoading_image noSelect" fill="#814DE5" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M12.691406 0L11.564453 2.3320312L9 2.6386719L10.949219 4.3613281L10.435547 7L12.691406 5.6816406L14.949219 7L14.435547 4.3613281L16.384766 2.6386719L13.820312 2.3320312L12.691406 0 z M 14.949219 7L10.435547 7L9.3007812 7C6.3307812 7 4 9.3307812 4 12.300781L4 45C4 45.55 4.45 46 5 46L22 46L22 36L28 36L28 46L45 46C45.55 46 46 45.55 46 45L46 12.300781C46 9.3307812 43.669219 7 40.699219 7L39.564453 7L35.050781 7L31.359375 7L26.845703 7L23.154297 7L18.640625 7L14.949219 7 z M 18.640625 7L20.896484 5.6816406L23.154297 7L22.640625 4.3613281L24.589844 2.6386719L22.025391 2.3320312L20.896484 0L19.769531 2.3320312L17.205078 2.6386719L19.154297 4.3613281L18.640625 7 z M 26.845703 7L29.103516 5.6816406L31.359375 7L30.845703 4.3613281L32.794922 2.6386719L30.230469 2.3320312L29.103516 0L27.974609 2.3320312L25.410156 2.6386719L27.359375 4.3613281L26.845703 7 z M 35.050781 7L37.308594 5.6816406L39.564453 7L39.050781 4.3613281L41 2.6386719L38.435547 2.3320312L37.308594 0L36.179688 2.3320312L33.615234 2.6386719L35.564453 4.3613281L35.050781 7 z M 10 12L16 12L16 16L10 16L10 12 z M 22 12L28 12L28 16L22 16L22 12 z M 34 12L40 12L40 16L34 16L34 12 z M 10 20L16 20L16 24L10 24L10 20 z M 22 20L28 20L28 24L22 24L22 20 z M 34 20L40 20L40 24L34 24L34 20 z M 10 28L16 28L16 32L10 32L10 28 z M 22 28L28 28L28 32L22 32L22 28 z M 34 28L40 28L40 32L34 32L34 28 z M 10 36L16 36L16 40L10 40L10 36 z M 34 36L40 36L40 40L34 40L34 36 z"></path>
                </g>
            </svg>

            <svg className="globalLoading_image noSelect" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.2848 18.9935C12.1567 19.0875 12.0373 19.1728 11.9282 19.2493C11.8118 19.1721 11.6827 19.0833 11.5427 18.9832C10.8826 18.5109 10.0265 17.8176 9.18338 16.9529C7.45402 15.1792 6 12.9151 6 10.5C6 7.18629 8.68629 4.5 12 4.5C15.3137 4.5 18 7.18629 18 10.5C18 12.8892 16.4819 15.1468 14.6893 16.9393C13.8196 17.8091 12.9444 18.5099 12.2848 18.9935ZM19.5 10.5C19.5 16.5 12 21 12 21C11.625 21 4.5 16.5 4.5 10.5C4.5 6.35786 7.85786 3 12 3C16.1421 3 19.5 6.35786 19.5 10.5ZM13.5 10.5C13.5 11.3284 12.8284 12 12 12C11.1716 12 10.5 11.3284 10.5 10.5C10.5 9.67157 11.1716 9 12 9C12.8284 9 13.5 9.67157 13.5 10.5ZM15 10.5C15 12.1569 13.6569 13.5 12 13.5C10.3431 13.5 9 12.1569 9 10.5C9 8.84315 10.3431 7.5 12 7.5C13.6569 7.5 15 8.84315 15 10.5Z" fill="#00A3FF"></path>
                </g>
            </svg>

            <svg className="globalLoading_image noSelect" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#b0b0b0">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M11.245 4.174C11.4765 3.50808 11.5922 3.17513 11.7634 3.08285C11.9115 3.00298 12.0898 3.00298 12.238 3.08285C12.4091 3.17513 12.5248 3.50808 12.7563 4.174L14.2866 8.57639C14.3525 8.76592 14.3854 8.86068 14.4448 8.93125C14.4972 8.99359 14.5641 9.04218 14.6396 9.07278C14.725 9.10743 14.8253 9.10947 15.0259 9.11356L19.6857 9.20852C20.3906 9.22288 20.743 9.23007 20.8837 9.36432C21.0054 9.48051 21.0605 9.65014 21.0303 9.81569C20.9955 10.007 20.7146 10.2199 20.1528 10.6459L16.4387 13.4616C16.2788 13.5829 16.1989 13.6435 16.1501 13.7217C16.107 13.7909 16.0815 13.8695 16.0757 13.9507C16.0692 14.0427 16.0982 14.1387 16.1563 14.3308L17.506 18.7919C17.7101 19.4667 17.8122 19.8041 17.728 19.9793C17.6551 20.131 17.5108 20.2358 17.344 20.2583C17.1513 20.2842 16.862 20.0829 16.2833 19.6802L12.4576 17.0181C12.2929 16.9035 12.2106 16.8462 12.1211 16.8239C12.042 16.8043 11.9593 16.8043 11.8803 16.8239C11.7908 16.8462 11.7084 16.9035 11.5437 17.0181L7.71805 19.6802C7.13937 20.0829 6.85003 20.2842 6.65733 20.2583C6.49056 20.2358 6.34626 20.131 6.27337 19.9793C6.18915 19.8041 6.29123 19.4667 6.49538 18.7919L7.84503 14.3308C7.90313 14.1387 7.93218 14.0427 7.92564 13.9507C7.91986 13.8695 7.89432 13.7909 7.85123 13.7217C7.80246 13.6435 7.72251 13.5829 7.56262 13.4616L3.84858 10.6459C3.28678 10.2199 3.00588 10.007 2.97101 9.81569C2.94082 9.65014 2.99594 9.48051 3.11767 9.36432C3.25831 9.23007 3.61074 9.22289 4.31559 9.20852L8.9754 9.11356C9.176 9.10947 9.27631 9.10743 9.36177 9.07278C9.43726 9.04218 9.50414 8.99359 9.55657 8.93125C9.61593 8.86068 9.64887 8.76592 9.71475 8.57639L11.245 4.174Z" stroke="#b0b0b0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
            </svg>

		</div>

		<div className="formSubTitle">DREAM4: Encontre os melhores quartos em hotéis 4 estrelas selecionados</div>		
	</div>

</div>
);	
}

// If globalLoading False, keep going
  return (
  <>
    <div className="wrapper">
      <div className="wrapper__header">
		<div className="wrapper__header_container">
			<div className="flex-1" onClick={() => { if (loginStatus && currentCompanyStatus === 1 && activationStatus) {navigate("/order");}}} style={{cursor:"pointer"}}>
				<img src={logo} style={{width:"auto"}} className="noSelect" />
			</div>	
			<div className="flex-5 tAlignL pL40">
				<Button label="Quem somos" text className="pT10 headerButton" onClick={()=>handleClickScroll('memovoSection')} />
				<Button label="Dúvidas frequentes" text className="pT10 headerButton" onClick={()=>handleClickScroll('contatoSection')}  />
			</div>			
			<div className={!loginStatus ? "flex-1 dFlex align-items-center justify-center" : "flex-1-5 dFlex align-items-center justify-center"}>
			
			{!loginStatus &&
			<>
				<Button label="Login" rounded className="pT10 mR10 headerButton tColorWhite" style={{width:"130px"}} onClick={()=>handleClickScroll('loginSection')} />
				<Button label="Cadastre-se" text className="pT10 headerButton tColorPink" style={{width:"147px"}} onClick={() => setDialog('registerModal',true)} />
			</>
			}

			{loginStatus &&
			<>	

				
				{/*<Button icon={<img src={iconRoute} style={{marginRight:"5px",width:"30px",height:"auto"}} />} size="large" rounded text aria-label="Menu" onClick={()=>setRouteManage(!routeManage)} label="Meus Roteiros" style={{fontSize:"1rem",color:"#222"}} />*/}
				
				{/*masterUser &&
				<>
				
						{!currentAgency.agencyCode &&
						<>
						<Button icon="pi pi-plus" size="large" rounded text aria-label="Menu" onClick={()=>setCurrentAgencyAction()} label="Selecionar agência" style={{fontSize:"1rem",color:"#222"}} />
						</>
						}
						
						{currentAgency.agencyCode &&
						<>
						<div className="loggedInWrapper"><span> Reservando como <strong>{currentAgency.agencyName}</strong> <br /> Funcionário: <strong>{currentAgency.employeeName}</strong> </span><Button icon="pi pi-times" size="small" rounded text aria-label="Menu" onClick={()=>resetCurrentAgencyAction()} label="Deslogar" style={{fontSize:"1rem",color:"#222"}} />
						</div>
						</>
						}

				</>
				*/}
			
				
				{/* IF inside admin module */}
				{pathname.includes("admin") &&
				<>
				<Button icon="pi pi-sign-out" size="large" rounded text aria-label="Menu" onClick={(e) => {navigate("/order")} } label="Sair da área da agência" style={{fontSize:"1rem"}} />
				</>
				 }				
			
			    {!pathname.includes("admin") &&
				<>
				<AuthLogin masterUser={masterUser} agencyUser={agencyUser} agencyName={agencyName} menuOpened={menuOpened} op={op} currentAgency={currentAgency} setCurrentAgencyAction={setCurrentAgencyAction}  />
				<OverlayPanel className="overlayPanel" onHide={onHide} onShow={onShow}  ref={op} style={{padding:"0 !important",backgroundColor:"#FFFFFF",width:"185px"}}>
				{/*minHeight:"150px"*/}
					
					{masterUser &&				
					<>
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Painel" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/dashboard");op.current.toggle(e);e.currentTarget.blur()}}  /></div>		
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Agências" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agencies");op.current.toggle(e);e.currentTarget.blur()}} /></div>	

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Fila de aprovação" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agenciesApproval");op.current.toggle(e);e.currentTarget.blur()}} /></div>	
					</>
					}				

					{(currentCompanyStatus === 1 || masterUser) &&
					<>
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Perfil da agência" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agencyProfile");op.current.toggle(e);e.currentTarget.blur()}} /></div>		
						

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Perfil do usuário" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/userProfile");op.current.toggle(e);e.currentTarget.blur()}} /></div>							

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Usuários" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/users");op.current.toggle(e);e.currentTarget.blur()}}  /></div>
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Clientes" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/customers");op.current.toggle(e);e.currentTarget.blur()}}  /></div>

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Reservas" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/reservations");op.current.toggle(e);e.currentTarget.blur()}}  /></div>						
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",display:"flex",alignItems:"center"}} > <Button severity="secondary"  text label="Relatórios" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/reports");op.current.toggle(e);e.currentTarget.blur()}}  /> </div>
					</>
					}
					
					{masterUser &&
					<>
					<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Configurações" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/configurations");op.current.toggle(e);e.currentTarget.blur()}} /></div>
					</>
					}					
					
					<ConfirmPopup />
					<Button label="Fazer logoff" className="mR10" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e) => {confirm(e)}} />
					
				</OverlayPanel>				
				</>				
				}				
				
				
			</>				
			}
			
			
			</div>				
		</div>
	  </div>
      <div className="wrapper__content">        
          <div className="wrapper__content_container home bgPurple">
		     <Routes>
				{getRoutes(routes)}	
				<Route
					path="*"
					element={<Navigate to="/home" replace />}
				/>				
			  </Routes>
          </div>  
      </div>  
	  <div className="wrapper__footer" style={{background:"#00A3FF",backgroundColor:"#00A3FF"}}>
		<div className="wrapper__footer_container">
			&copy;2024 Todos os direitos reservados a MTDNA
		</div>
	  </div>
	  <ScrollTop threshold={100} />
    </div>
	
	<RegisterView
		setDialog={setDialog}
		dialog={dialog}
		alert={alert}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}	
		setAlertAction={setAlertAction}	
		setValidateRegistry={setValidateRegistry}		
	/>	

	<ValidateView
		alert={alert}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}	
		setAlertAction={setAlertAction}	
		validateRegistry={validateRegistry}
		setValidateRegistry={setValidateRegistry}
		scrollBlock={false}				
	/>	
	
	<SelectAgencyView
		setDialog={setDialog}
		dialog={dialog}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}
		scrollBlock={false}			
	/>		

	<AlertView
		alert={alert}
		setAlert={setAlert}
		alertTitle={alertTitle}
		alertBody={alertBody}
		alertAction={alertAction}
		scrollBlock={false}				
	/>

	{/*<RouteManage
		setDialog={setDialog}
		dialog={dialog}
	/>*/}	
	</>	
  );
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser,
  routePanel:store.appReducer.routePanel,
  routeManage:store.appReducer.routeManage,
  loginStatus:store.appReducer.loginStatus,
  currentAgency:store.appReducer.currentAgency,
  agencyName:store.appReducer.agencyName,
  agencyUser:store.appReducer.agencyUser,
  currentCompanyStatus:store.appReducer.currentCompanyStatus,
  activationStatus:store.appReducer.activationStatus
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Desktop);	
