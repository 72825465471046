import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import {APIProvider, Map,Marker, InfoWindow} from '@vis.gl/react-google-maps';
import { ProgressSpinner } from 'primereact/progressspinner';

// Load Actions
import * as AppActions from '../../Store/Actions/App';


const apiKey = 'AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs';


// UpdateService dialog component
const PropertyPoiView = (props) => {

// set vars from props
const { 
    dialog,
    setDialog,
	pois,
    type,
	address,
    zoom: initialZoom,
    coordinates,
	scrollBlock
} = props;


const [loading, setLoading] = React.useState(false); 
const [center, setCenter] = React.useState(null);
const [markerCenter, setMarkerCenter] = React.useState(null);
const [selectedPoi, setSelectedPoi] = React.useState(null);
const [zoom, setZoom] = React.useState(initialZoom || 11);

// Estilos do mapa para remover apenas hotéis
// https://developers.google.com/maps/documentation/javascript/style-reference?hl=pt-br
/*const mapOptions = {
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "simplified" }], // Mantém rótulos de POIs
      },
      {
        featureType: "poi.attraction",
        elementType: "all",
        stylers: [{ visibility: "on" }], // Mantém pontos turísticos
      }
    ],
  };*/

const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);	

const geocodeAddress = async (address) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();
    if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
    } else {
        throw new Error('Geocoding API error: ' + data.status);
    }
};	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'PropertyPoiViewModal' && dialog.status) {
  
        if (type == "address") {

            geocodeAddress(address)
                .then(coords => {
                    setCenter(coords);
                    setMarkerCenter(coords);
                })
                .catch(error => {
                    console.error('Error fetching geocode:', error);
                });   
                
            } // end if
            else {
                setCenter(coordinates);
                setMarkerCenter(coordinates);
            } /// end else        
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

// Execute when dialog opens
React.useEffect(() => {
	
	// Reset all fields when closes or open dialog
  // Runs when UNMOUNT component
  return () => {
    
  };
			
},[]);

const close = ()=> {

	setDialog("PropertyPoiViewModal",false);
    setSelectedPoi(null);
    
	setTimeout(function() {
		if (scrollBlock) {
			// document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300); 

}

	
	return (
	<>
			<Dialog header="Pontos de interesse" closable={true} closeOnEscape={true} dismissableMask={true} visible={dialog.target == 'PropertyPoiViewModal' && dialog.status} style={{ width: '75vw',height:'90vh' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
				<div className="flex-row justify-center align-items-center w100" style={{padding:"1rem",height:"100%",gap:"2rem"}}>
                        
                    {loading &&
			                <div style={{backgroundColor:"transparent",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"45vh",padding:"30px"}}>

                                <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"20vw",textAlign:"center"}}>
                                    <ProgressSpinner />
                                    <div className="formTitle" style={{marginBottom:"15px",marginTop:"15px",fontSize:"2rem"}}>Carregando...Aguarde! </div>
                                </div>

                            </div>           
                    }

                    {!loading &&
                    <>

                    <div className="flex-column justify-center align-items-start" style={{alignSelf:"baseline",flex:0.4}}>
                       
                        <div style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                        fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>O que há nos arredores...</div>

                        <div className="resultsCard_details__amenities w50 flex-column justify-center align-items-start applyPointer" style={{gap:"1rem",marginTop:"1rem",overflowY:"scroll",height: '60vh', justifyContent: 'flex-start', padding: '10px',maxHeight:"60vh"}}>
                            {pois.map((item, index) =>(<div key={item.poiid_ppn} className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}} onClick={() => setSelectedPoi(item)}>
                                        <div>                                                                     
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                            </svg>   
                                        </div>
                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.poi_name}</div>
                                        <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>{item.distancia ? item.distancia.toFixed(2)+" km" : ""}</div>
                                        </div>))}                                    
                            
                        </div>
                    </div>
                    </>
                    }

                    {(center && !loading) &&
                    <div style={{ flex:1,height: 'inherit', width: 'inherit' }}>
                    <APIProvider apiKey={apiKey}>
                        <Map
                        defaultZoom={15}
                        defaultCenter={center}
                        gestureHandling={'greedy'}
                        onZoomChanged={(e) => setZoom(e.zoom)}
                        disableDefaultUI={false}
                        fullscreenControlOptions={false}
                        mapTypeControl={false}
                        streetViewControl={false}
                        fullscreenControl={false}
                        /*options={mapOptions}*/
                        > 
                        <Marker position={{lat: parseFloat(markerCenter.lat), lng:parseFloat(markerCenter.lng)}} />

                        {pois.map((poi) => (
                                            <Marker key={poi.poiid_ppn} position={{ lat: parseFloat(poi.latitude), lng: parseFloat(poi.longitude) }} 
                                                onClick={() => setSelectedPoi(poi)}
                                                icon={{
                                                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"/*,
                                                    scaledSize: new window.google.maps.Size(40, 40)*/
                                                }}
                                            />
                          ))}

                            {selectedPoi && (
                                    <InfoWindow options={{pixelOffset: new window.google.maps.Size(0, -40)}} position={{ lat: parseFloat(selectedPoi.latitude), lng: parseFloat(selectedPoi.longitude) }} 
                                        onCloseClick={() => setSelectedPoi(null)}>
                                        <div>
                                            <h4 style={{marginBottom:"2px",paddingBottom:"2px"}}>{selectedPoi.poi_name}</h4>
                                            <h4 style={{color:"silver",marginTop:"2px",paddingTop:"2px"}}>{selectedPoi.category}</h4>
                                        </div>
                                    </InfoWindow>
                                )}                             

                        </Map>
                    </APIProvider>
                    </div>
                    }                    

			    </div>
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(PropertyPoiView);	
